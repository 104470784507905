<template>
  <form class="upload-files">
    <div id="upload">
      <input type="file" :id="idInput" multiple="multiple" :disabled="!multiple && attachments.length > 0" @change="uploadFieldChange" style="display:none;">
      <label :for="idInput">
        <div class="upload-files-files" v-if="attachments.length">
          <div class="file" v-for="(attachment, index) in attachments" :key="index">
            <div>{{ attachment.name }}</div> {{ '(' + Number((attachment.size / 1024 / 1024).toFixed(1)) + 'MB)' }}
          </div>
          <div class="remove-file" @click="removeAttachment(attachment)">
            x
          </div>
        </div>
        <div id="upload-files-message" v-if="!attachments.length">
          <span class="upload-files-title">Haga clic para seleccionar</span>
          <span class="upload-files-info" v-if="multiple">Puedes subir varios archivos a la vez</span>
        </div>

        <div class="submit-files" @click="uploadFiles" v-if="attachments.length">
          <button type="button">Subir</button>
        </div>
      </label>
    </div>
  </form>
</template>

<script>

export default {
  name: "upload-files-form",
  props: {
    'idInput': {
      type: String,
    }, 
    'multiple': {
      type: Boolean,
      default: false
    },
    'fileType': {
      type: String
    }
  },
  data: () => ({
    attachments: [],
    dataAttachments: new FormData(),
    uploadProccess: 0
  }),
  mounted() {},
  computed: {
    user() { return this.$store.state.user.data }
  },
  methods: {
    getAttachmentSize() {
      this.upload_size = 0;
      this.attachments.map(item => {
        this.upload_size += parseInt(item.size);
      });

      this.upload_size = Number(this.upload_size.toFixed(1));
      this.$forceUpdate();
    },
    prepareFields() {
      if (this.attachments.length > 1) {
        for (var i = 0; i < this.attachments.length; i++) {
          let attachment = this.attachments[i];
          this.dataAttachments.append("images[]", this.attachments[i]);
        }
      } else if (this.attachments.length == 1) {
        this.dataAttachments.append("image", this.attachments[0]);
      }

      this.attachments = []
    },
    uploadFiles() {
      this.$showLoader(true)
      this.prepareFields()
      this.$axios.post(this.$store.state.app.OPS.UPLOAD_FILE, this.dataAttachments, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'file-type': this.fileType,
          'user-id': String(this.user.id),
        }
      }).then(res => {
        this.$store.dispatch('user/getUserFiles')
        this.$showLoader(false)
        console.log(res)
      }).catch(err => {
        console.log(err)
      })
    },
    removeAttachment(attachment) {
      this.attachments.splice(this.attachments.indexOf(attachment), 1);
      this.getAttachmentSize();
    },
    uploadFieldChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      for (var i = files.length - 1; i >= 0; i--) {
        this.attachments.push(files[i]);
        console.log(this.attachments)
      }
      document.getElementById(this.idInput).value = [];
    }
  }
};
</script>

<style scoped>
.upload-files {
  width: 100%;
}

#upload label {
  width: 100%;
  height: 150px;
  background-color: #efeff1;
  border-radius: 10px;
}

div#upload-files-message {
    text-align: center;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.upload-files-files {
    height: 110px;
    display: flex;
    align-items: center;
    padding: 30px;
}

.file {
    width: 90%;
    border: 1px solid white;
    padding: 10px;
    border-radius: 5px;
}

.file div {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.remove-file {
    padding: 10px;
    color: red;
    font-size: 23px;
    cursor: pointer;
}

.submit-files {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.submit-files button {
  border: none;
  background-color: #5e72e4;
  border-radius: 3px;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  padding: 2px 10px;
  cursor: pointer;
}
</style>