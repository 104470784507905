<template>
    <div v-if="window.width < window.height" id="pleaserotate-backdrop" style="display: block;">
        <div id="pleaserotate-container">
            <svg xmlns:xlink="http://www.w3.org/1999/xlink" id="pleaserotate-graphic" viewBox="0 0 250 250">
                <g id="pleaserotate-graphic-path">
                    <path d="M190.5,221.3c0,8.3-6.8,15-15,15H80.2c-8.3,0-15-6.8-15-15V28.7c0-8.3,6.8-15,15-15h95.3c8.3,0,15,6.8,15,15V221.3zM74.4,33.5l-0.1,139.2c0,8.3,0,17.9,0,21.5c0,3.6,0,6.9,0,7.3c0,0.5,0.2,0.8,0.4,0.8s7.2,0,15.4,0h75.6c8.3,0,15.1,0,15.2,0s0.2-6.8,0.2-15V33.5c0-2.6-1-5-2.6-6.5c-1.3-1.3-3-2.1-4.9-2.1H81.9c-2.7,0-5,1.6-6.3,4C74.9,30.2,74.4,31.8,74.4,33.5zM127.7,207c-5.4,0-9.8,5.1-9.8,11.3s4.4,11.3,9.8,11.3s9.8-5.1,9.8-11.3S133.2,207,127.7,207z">
                    </path>
                </g>
            </svg>
            <div id="pleaserotate-message">
                Por favor, activa la rotación automática de tu dispositivo y rótalo
                <small>(Esta página sólo funciona en orientación horizontal)</small>
            </div>
        </div>
    </div>
    <section v-else class="section section-shaped section-lg my-0" style="background-color: #FFFF; background-size: cover; opacity: 0.80; padding:4%;">
        <div
      v-if="!permissionGPS"
      class="container pt-lg-md"
      style="padding:10%; background-color:#FFFF;"
    >
      <div class="row justify-content-center">
        <div class="col-lg-10" style="opacity: 0.95">
          <card
          style="background-color:#FFFF;"
            shadow
            header-classes="bg-white pb-5"
            body-classes="px-lg-10 py-lg-10"
            class="border-0"
          >
            <template>
              <div>
                <div class="text-center text-muted mb-4">
                  <div class="row justify-content-around align-items-center">
                    <div
                      class="col-lg-12 col-md-12 col-sm-12 col-12"
                      style="text-align: center"
                    >
                      <br />
                      <h4 style="color:black">Antes de firmar</h4>
                      <span style="color:black"
                        >¿Permites que Finamigo tenga acceso a la ubicación de tu
                        dispositivo cada vez que inicies sesión?
                      </span>
                      <span style="color:black"
                        >Por disposición oficial, esto es necesario para poder
                        seguir con tu progreso.</span
                      >
                    </div>
                    <div class="row d-flex justify-content-around">
                      <div class="col-12">
                        <br />
                        <base-button
                           style="background-color: #d3157b; font-size: 15pt; border-radius:10px; color:white"
                          block
                          v-on:click="acceptLocalization()"
                          >Dar permiso</base-button
                        >
                      </div>
                      <div class="col-12" style="margin-top: 10px">
                        <span
                          style="color: #2b8c9c; font-size: 13px"
                          @click="permissionsModal = true"
                          >No dar permiso</span
                        >
                      </div>
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
            </template>
          </card>
        </div>
      </div>
    </div>
    <modal :show.sync="permissionsModal" style="background-color:#ffff">
      <div class="text-center">
        <icon
          name="ni ni-notification-70"
          type="info"
          rounded
          class="mb-4"
        ></icon>
        <br />
        <p>
          Te recomendamos que, por disposición oficial, necesitamos que concedas
          el permiso de ubicación para que podamos continuar.
        </p>

        <base-button type="info" v-on:click="acceptLocalization()"
          >Dar permiso</base-button
        >
      </div>
    </modal>
    <div v-if="permissionGPS">
        <div  class="container" style="padding-bottom:40px">
            <div class="row justify-content-center">
                <div class="col-lg-12" style="opacity: 0.95">
                    <card shadow
                          header-classes="bg-white pb-5"
                          body-classes="px-lg-10 py-lg-10"
                          style="background-color: #f5f5f5; background-size: cover; opacity: 0.80"
                          class="border-0">
                        <div class="row justify-content-around align-items-center text-center">
                            <div class="col-lg-12">
                              <img src="https://dbmenos.com/img/finamigo.png" class="img-fluid" width="20%" alt="Finamigo Logo">
                            </div>
                        </div>
                    </card>
                </div>
            </div>
        </div>
        <div class="container pt-lg-md" style="min-height: 730px">
            <div class="row justify-content-center">
                <div class="col-lg-10" style="opacity: 0.95">
                    <card shadow
                           style="background-color: #f5f5f5; background-size: cover; opacity: 0.80; padding:4%;"
                          header-classes="bg-white pb-5"
                          body-classes="px-lg-10 py-lg-10"
                          class="border-0">
                        <template>
                            <div class="justify-content-around align-items-center text-center" v-if="validToken">
                                  <div v-if="signed == 1">
                                    <div class="text-center">
                                        <h5>Gracias por tu firma, en cuanto tu cuenta este creada, recibirás tu contrato a través de whatsapp</h5>
                                        <img src="img/theme/checkbox-marked-circle.png">
                                    </div>
                                </div>
                                <div v-if="signed == null">
                                    <div class="text-center text-muted mb-4" v-if="urlFile">
                                        <small>Firma en el recuadro inferior despúes de haber leído los documentos adjuntos para agregar este anexo a tu contrato actual.</small>
                                        <p >
                                            <a :href="urlFile" target="_blank"><b>Ver Contrato</b></a>
                                        </p>
                                    </div>
                                    <div class="text-center text-muted mb-4" v-if="!urlFile">
                                        <small>Firma en el recuadro inferior para anexar la firma a tu contrato de finamigo.</small>
                                    </div>
                                    <div style="text-align: center;">
                                    <div style="background-color: #FFFFFF; position: relative; margin-top:10px; width:90%; display: inline-block;">
                                        <VueSignaturePad
                                                height="190px"
                                                ref="signaturePad"/>
                                                
                                        <div class="signature mx-5 text-center noselect"><br>Firma<br><br></div>
                                    </div>
                                    </div>
                                    <div class="row d-flex justify-content-around">
                                        <base-button type="info" class="my-4" v-on:click="clearSignature()">Borrar firma</base-button>
                                        <base-button type="success" class="my-4" v-on:click="sendSignatureFA()">Firmar Documentos</base-button>
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="alreadySigned" class="text-center">
                                    <h5>Gracias por tu firma, en cuanto tu cuenta este creada, recibirás tu contrato a través de whatsapp.</h5>
                                    <img src="img/theme/checkbox-marked-circle.png">
                                </div>
                                <div v-else-if="notFound" class="text-center">
                                    <h5>No logramos encontrar el enlace para firmar, por favor comúnicate con tu asesor</h5>
                                <img src="img/theme/close-circle.png">
                                </div>
                            <div v-else class="text-center">
                                <h5>No logramos validar tu enlace, comúnicate con tu asesor</h5>
                                <img src="img/theme/close-circle.png">
                            </div>
                        </template>
                    </card>
                </div>
            </div>
        </div>
        </div>
    </section>
</template>
<script>
    import modal from "@/components/Modal";
    import Vue from "vue"
    import VueResource from "vue-resource"
    import {V} from "@/V"
    import VueSignaturePad from 'vue-signature-pad'
    import VueFacebookPixel from 'vue-analytics-facebook-pixel'
    Vue.use(VueFacebookPixel)
    Vue.use(VueResource)
    export default {
        components: {
            VueFacebookPixel,
            VueSignaturePad,
            modal
        },
        computed: {
            banks() { return this.$store.state.banks },
            nombre_banco: function() {
                return this.getBank(this.account.bank).name
            }
        },
        async created() {
            this.urlFile = JSON.parse(localStorage.getItem("urlFile"));
             this.phone = JSON.parse(localStorage.getItem("phone"));
            this.session = JSON.parse(localStorage.getItem("session"));
            window.addEventListener('resize', this.handleResize)
            this.handleResize()
            //await this.acceptLocalization();
            //this.getFilesInfo();
        },
        destroyed() {
            window.removeEventListener('resize', this.handleResize)
        },
        mounted() {
            this.handleResize()  
        },
        data() {
            return {
                permissionsModal: false,
                permissionGPS:false,
                urlFile:null,
                phone:null,
                session:null,
                token: null,
                sign: null,
                validToken: false,
                account: null,
                signToken: null,
                signed: null,
                signedAdd: null,
                waitingSignature: true,
                alreadySigned: false,
                notFound: false,
                signedForCreditReport: false,
                signedForContract: false,
                signaturePath: null,
                SIGN_CONTRACT: 'contract',
                SIGN_ACCOUNT_SETTLEMENT: 'account',
                SIGN_ADDENDUM: 'addendum',
                SIGN_LETTER_DBM : 'letter_dbm',
                cancelAddendum:false,
                urlLetter:"",
                urlContract:"",
                user: {
                    name: null,
                    address: null,
                    address_2: null,
                    phone: null,
                    email: null
                },

                window: {
                    width: 0,
                    height: 0
                },
                userFiles: [],
                urlAddendum:"",
                files: {
                APPROACH_ADDENDUM: null
            },
            }
        },
        methods: {
        acceptLocalization() {

          this.$showLoader(true)
      if (!("geolocation" in navigator)) {
        this.$showLoader(false)
        console.log(
          "Tu navegador no soporta el acceso a la ubicación. Intenta con otro"
        );
      }
      // get position
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          let url = V.SERVER + V.PUBLIC_OPS.SET_GEOLOCATION;
          let headers={
            token:this.$route.query.tkFA
          }
          let body = {
            latitude: pos.coords.latitude,
            longitude: pos.coords.longitude
          };
          console.log('Entro')
          this.$axios.post(url, body,{headers:headers}).then((response) => {
             this.$showLoader(false)
            let data = response.data;
            if (data[V.API.SUCCESS]) {
              this.$showLoader(false)
              this.permissionGPS = true;
              console.log("Ubicación Guardada");
              this.validateToken()
              if(this.permissionsModal == true){
                  this.permissionsModal=false;
              }

            this.$forceUpdate();
              return true;
            } else {
              this.$showLoader(false)
              console.log("Error");
            }
          });
        },
        (err) => {
          switch (err.code) {
            case err.PERMISSION_DENIED:
              this.$showLoader(false)
              console.log(err);
              return false;
              // El usuario denegó el permiso para la Geolocalización.
              break;
            case err.POSITION_UNAVAILABLE:
              this.$showLoader(false)
              console.log(err);
              // La ubicación no está disponible.
              break;
            case err.TIMEOUT:
              this.$showLoader(false)
              console.log(err);
              // Se ha excedido el tiempo para obtener la ubicación.
              break;
            case err.UNKNOWN_ERROR:
              this.$showLoader(false)
              console.log(err);
              // Un error desconocido.
              break;
          }
        }
      );
    },
            clearSignature() {
                this.$refs.signaturePad.undoSignature()
            },
            sendSignatureFA(){
                 this.$showLoader(true)
                 console.log('Hola')
                const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
                if (!isEmpty && data) {
                    this.signatureData = data
                    let block = data.split(";")
                    let contentType = block[0].split(":")[1]
                    let sData = block[1].split(',')[1]
                    let blob = this.base64ToBlob(sData, contentType)
                    let form = new FormData();
                    form.append("signature", blob);
                    let headers = {
                        "token": this.token
                    }
                    let url = V.SERVER + V.PUBLIC_OPS.SET_FINAMIGO_SIGNATURE
                    this.$axios.post(url, form, {headers: headers}).then(res => {
                        console.log(res.data.success)
                        this.$showLoader(false)
                        if (res.data.success || res.data.reason==5) {
                            this.signed = true
                            this.$notify({
              text: "Firma Recibida Correctamente",
              type: "success",
            });

                        localStorage.removeItem('urlFile')
                        localStorage.removeItem('session')
                        localStorage.removeItem('phone')
                        localStorage.removeItem('INEA')
                        localStorage.removeItem('INEB')

                      setTimeout(() => {
          this.$router.push({
          path: "/finish",
        });
        }, 6000);
                        }else{
                            this.$notify({
              text: "Algo salió mal, intenta nuevamente",
              type: "error",
            });
                        }
                    }).catch(e => {
//                        console.log(e)
                        this.$showLoader(false)
                        this.$notify({
              text: "Algo salió mal, intenta nuevamente",
              type: "error",
            });
                    })
                }
            },
             validateToken() {
                 this.$showLoader(true)
                if (this.$route.query.tkFA) {
                    this.sign = this.$route.query.sign
                    let body={
                        token:this.$route.query.tkFA
                    }
                    let url = V.SERVER + V.PUBLIC_OPS.VALIDATE_SIGNATURE_FA_TOKEN;
                    return this.$axios.post(url,body).then(response => {
                        this.$showLoader(false)
                        let data = response.data
                        let _token = data.row
                        if (data.success) {
                            this.token = this.$route.query.tkFA
                            this.validToken = true
                            if(data.url){
                              this.urlFile=data.url
                            }
                            this.alreadySigned = _token.signed
                            this.signaturePath = _token.path;
                            this.$forceUpdate()
                        }
                        else if(data.reason == 5) {
                            this.alreadySigned = true
                        }else if(data.reason == 1) {
                            this.notFound = true
                        }
                    })
                }else{ this.$showLoader(false)}
            },
            base64ToBlob(b64Data, contentType, sliceSize) {
                contentType = contentType || '';
                sliceSize = sliceSize || 512;

                let byteCharacters = atob(b64Data);
                let byteArrays = [];

                for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                    let slice = byteCharacters.slice(offset, offset + sliceSize);

                    let byteNumbers = new Array(slice.length);
                    for (let i = 0; i < slice.length; i++) {
                        byteNumbers[i] = slice.charCodeAt(i);
                    }

                    let byteArray = new Uint8Array(byteNumbers);

                    byteArrays.push(byteArray);
                }

                return new Blob(byteArrays, {type: contentType});
            },
            handleResize() {
                this.window.width = window.innerWidth;
                this.window.height = window.innerHeight;

                let footer = document.getElementById("footer")

                if (this.window.width < this.window.height) {
                    if (footer) footer.style.display = "none";
                } else {
                    if (footer) footer.style.display = "block";
                }
            }
        }
    };
</script>
<style>
    .signature {
        border: 0;
        border-top: 1px solid #000;

        left: 0;
        right: 0;
        bottom: -15px;
        margin: auto;
        position: absolute;
        pointer-events: none;
    }
    .noselect {
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Opera and Firefox */
    }
    #pleaserotate-graphic {
        margin-left: 50px;
        width: 200px;
        animation:
        pleaserotateframes ease 2s;
        animation-iteration-count: infinite;
        transform-origin: 50% 50%;
        -webkit-animation: pleaserotateframes ease 2s;
        -webkit-animation-iteration-count: infinite;
        -webkit-transform-origin: 50% 50%;
        -moz-animation: pleaserotateframes ease 2s;
        -moz-animation-iteration-count: infinite;
        -moz-transform-origin: 50% 50%;
        -ms-animation: pleaserotateframes ease 2s;
        -ms-animation-iteration-count: infinite;
        -ms-transform-origin: 50% 50%;
    }
    #pleaserotate-backdrop {
        background-color: white;
        top: 0; left: 0; 
        position: fixed; 
        width: 100%; 
        height: 100%; 
    }
    #pleaserotate-container {
        width: 300px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
    }
    #pleaserotate-message {
        margin-top: 20px;
        font-size: 1.3em;
        text-align: center;
        font-family: Verdana, Geneva, sans-serif;
        text-transform: uppercase
    }
    #pleaserotate-message small {
        opacity: .5;
        display: block;
        font-size: .6em
    }
    @keyframes pleaserotateframes {
        0% {
            transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            -ms-transform: rotate(0deg) ;
        }
        49% { 
            transform: rotate(-90deg);
            -moz-transform: rotate(-90deg);
            -webkit-transform: rotate(-90deg);
            -ms-transform: rotate(-90deg);
        }
        100% {
            transform: rotate(90deg);
            -moz-transform: rotate(-90deg);
            -webkit-transform: rotate(-90deg);
            -ms-transform: rotate(-90deg);
        }
    }
</style>
