import Vue from "vue"
import axios from 'axios'
import store from './store'
import App from "./App.vue"
import router from "./router"

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

//import Argon from "./plugins/argon-kit"
import ArgonDashboard from './plugins/argon-dashboard'

import BootstrapVue from 'bootstrap-vue'
import es from 'vee-validate/dist/locale/es'
import VeeValidate, {Validator} from 'vee-validate'
import ImageUploader from 'vue-image-upload-resize'
import {V} from "./V"
import numeral from 'numeral'
import moment from 'moment'
import Notification from 'vue-notification'
import VueMeta from 'vue-meta'

//configAxios
const instance = axios.create({baseURL: V.SERVER})
instance.interceptors.request.use(function (config) {
    let session = null
    let user = null
    let phone = null
    let sessionData = JSON.parse(localStorage.getItem(V.STORE_KEYS.SESSION))
    if(sessionData) { session = sessionData[V.STORE_KEYS.SESSION]}
    let userData = JSON.parse(localStorage.getItem(V.STORE_KEYS.SESSION))
    if(userData) { user = sessionData[V.STORE_KEYS.USER]}
    let phoneData = JSON.parse(localStorage.getItem(V.STORE_KEYS.PHONE))
    if(phoneData) { phone = phoneData[V.STORE_KEYS.PHONE]}
    if (session) {
        config.headers.common.session = session
        config.headers.common.user = user
        config.headers.common.phone = phone
    }

    return config
}, function (error) {
    console.log(error)
})

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(ImageUploader);
Vue.use(ArgonDashboard)

Vue.prototype.$axios = axios
Vue.prototype.$showLoader = showLoader
Vue.prototype.$moment = moment
Vue.prototype.$validateSession = validateSession
Vue.prototype.$formatDate = formatDate
Vue.prototype.$formatCurrency = formatCurrency
Vue.prototype.$getLS = getLS
Vue.prototype.$setLS = setLS
Vue.prototype.$v = V

Vue.use(Notification)
Vue.use(VeeValidate)
Validator.localize('es', es)
Vue.use(VueMeta)

Vue.prototype.$axios = instance

new Vue({
    router,
    render: h => h(App),
    store: store,
    async beforeCreate() {
        await this.$store.dispatch('downloadBanks')
        await this.$store.dispatch('downloadEconomicActivities')
    }
}).$mount("#app")

//Session
function setLS(key, json) {
    localStorage.setItem(key, JSON.stringify(json))
}
function getLS(key) {
    let item = localStorage.getItem(key)
    if (item) {
        try {
            return JSON.parse(item)
        } catch (e) {
            return item
        }
    } else if (key === V.STORE_KEYS.SESSION) {
        router.push("/login")
    }
    return null
}
function validateSession(json) {
    if (!json['success']) {
        if (json['reason'] === 7) {
            // Session expired
            router.push("/login")
        }
    }
}

function showLoader(show) {
    this.$store.commit('setShowLoader', show)
}

//FormatosFecha&Moneda
function formatDate(date) {
    return moment(date).format("DD MMM, YYYY")
}
function formatCurrency(num) {
    return numeral(num).format('$ 0,0.00')
}