<template>
  <section class="section section-sm text-center">
    <div class="container pt-lg-md text-center">
      <div class="row justify-content-center text-center">
        <div class="col-lg-6 text-center" style="opacity: 0.95">
          <card
            class="col-lg-12 text-center"
            style="border: 0; border-radius: 10px"
          >
            <img
              src="https://dbmenos.com/img/finamigo/finamigo_ine.jpg"
              style="width: 100%; margin-bottom: 2rem"
            />
          </card>
        </div>
        <div class="col-lg-6 text-center" style="margin-top: 10%">
          <h4 style="font-size: 20px" class="display-4">
            Para poder continuar con tu apertura de cuenta, es necesario
            proporcionar tu credencial del INE de Frente y Trasera.
            <br />
            Te pedimos le tomes una foto a tu credencial:
            <br />
            - Sin flash
            <br />
            - Que se logren ver las 4 esquinas
            <br />
            - Credencial Vigente
          </h4>
          <div
            v-if="!a && !ineA"
            style="
              text-align: center;
              text-transform: uppercase;
              margin-top: 10%;
            "
          >
            <label>Identificación Frente</label>
            <div>
              <dropbox-resize
                text
                :ocrFile="true"
                :files="this.userFiles"
                type="ID"
                v-on:uploadFile="uploadFileFrente"
                v-on:resentUploadFile="uploadFileFrente"
              ></dropbox-resize>
            </div>
          </div>
          <div
            v-if="!b && !ineB"
            style="
              text-align: center;
              text-transform: uppercase;
              margin-top: 30%;
              margin-bottom: 30%;
            "
          >
            <label>Identificación Trasera</label>
            <div>
              <dropbox-resize
                text
                :ocrFile="true"
                type="ID_B"
                :files="this.userFiles"
                v-on:uploadFile="uploadFileTrasera"
                v-on:resentUploadFile="uploadFileTrasera"
              ></dropbox-resize>
            </div>
          </div>
        </div>
      </div>
      <modal
        :show.sync="uploadINEA"
        body-classes="p-0"
        modal-classes="modal-dialog-centered modal-sm"
        class="modal-fluid"
      >
        <card class="border-0">
          <template>
            <div class="text-center text-muted mb-4" v-if="loading">
              <h4 class="display-4" style="margin-top: 1rem; font-size: 2rem">
                Subiendo Identificación Oficial y Analizando datos....
              </h4>
              <br />
              <b-spinner
              class="spinner-border text-warning"
                style="width: 3rem; height: 3rem; margin-top: 1rem"
                label="Large Spinner"
              ></b-spinner>
            </div>
            <div
              class="text-center text-muted mb-4"
              v-if="!loading && dataUsers"
            >
              <h3 class="display-4" style="color: #d3157b">Datos obtenidos:</h3>
              <span style="margin-top: 1rem" v-if="dataUsers.name"
                >Nombre:
                <b>{{ dataUsers.name ? dataUsers.name : "---" }}</b></span
              >
              <br />
              <span style="margin-top: 1rem" v-if="dataUsers.lastname_1"
                >Primer Apellido:
                <b>{{
                  dataUsers.lastname_1 ? dataUsers.lastname_1 : "---"
                }}</b></span
              >
              <br />
              <span style="margin-top: 1rem" v-if="dataUsers.lastname_2"
                >Segundo Apellido:
                <b>{{
                  dataUsers.lastname_2 ? dataUsers.lastname_2 : "---"
                }}</b></span
              >
              <br />
              <span style="margin-top: 1rem" v-if="dataUsers.curp"
                >CURP:
                <b>{{ dataUsers.curp ? dataUsers.curp : "---" }}</b></span
              >
              <br />
              <span style="margin-top: 1rem" v-if="dataUsers.rfc"
                >RFC: <b>{{ dataUsers.rfc ? dataUsers.rfc : "---" }}</b></span
              >
              <br />
              <span style="margin-top: 1rem" v-if="dataUsers.gender"
                >Género:
                <b>{{
                  dataUsers.gender == "F" ? "Femenino" : "Masculino"
                }}</b></span
              >
              <br />
              <span style="margin-top: 1rem" v-if="dataUsers.birthday"
                >Fecha de Nacimiento:
                <b>{{
                  dataUsers.birthday
                    ? this.$moment(dataUsers.birthday).format("DD-MM-YYYY")
                    : "----"
                }}</b></span
              >
              <br />
              <span style="margin-top: 1rem" v-if="dataUsers.addr_street"
                >Calle:
                <b>{{
                  dataUsers.addr_street ? dataUsers.addr_street : "----"
                }}</b></span
              >
              <br />
              <span style="margin-top: 1rem" v-if="dataUsers.addr_extnum"
                >Número Externo:
                <b>{{
                  dataUsers.addr_extnum ? dataUsers.addr_extnum : "----"
                }}</b></span
              >
              <br v-if="dataUsers.addr_intnum" />
              <span style="margin-top: 1rem" v-if="dataUsers.addr_intnum"
                >Número Interno:
                <b>{{
                  dataUsers.addr_intnum ? dataUsers.addr_intnum : "----"
                }}</b></span
              >
              <br />
              <span style="margin-top: 1rem" v-if="dataUsers.addr_postal_code"
                >Código Postal:
                <b>{{
                  dataUsers.addr_postal_code
                    ? dataUsers.addr_postal_code
                    : "----"
                }}</b></span
              >
              <br />
              <span style="margin-top: 1rem" v-if="dataUsers.addr_sublocality"
                >Colonia:
                <b>{{
                  dataUsers.addr_sublocality
                    ? dataUsers.addr_sublocality
                    : "----"
                }}</b></span
              >
              <br />
              <span style="margin-top: 1rem" v-if="stateOfBirth"
                >Estado de Nacimiento:
                <b>{{ stateOfBirth ? stateOfBirth : "----" }}</b></span
              >
              <br />
              <base-button
                slot="title"
                type="primary"
                class="btn"
                style="
                  background-color: #d3157b;
                  border-color: #d3157b;
                  color: #ffff;
                  width: 60%;
                  border-radius: 10px;
                  margin-top: 1rem;
                "
                size="xl"
                v-on:click="closeModalA()"
                >{{ "Continuar" }}</base-button
              >
            </div>
            <div
              class="text-center text-muted mb-4"
              v-if="errorOCR && dataError"
            >
              <h4 class="display-4" style="margin-top: 1rem; font-size: 3rem">
                Error al subir la ine
              </h4>
              <span style="margin-top: 1rem; font-size: 2rem; color: red">{{
                dataError.error
              }}</span>
              <br />
              <base-button
                slot="title"
                type="primary"
                class="btn"
                style="
                  background-color: #d3157b;
                  border-color: #d3157b;
                  color: #ffff;
                  width: 60%;
                  border-radius: 10px;
                  margin-top: 1rem;
                "
                size="xl"
                v-on:click="closeModalA()"
                >{{ "Cerrar" }}</base-button
              >
            </div>
            <div
              class="text-center text-muted mb-4"
              v-if="errorOCR && !dataError"
            >
              <h4 class="display-4" style="margin-top: 1rem; font-size: 3rem">
                Error al subir la ine
              </h4>
              <span style="margin-top: 1rem; font-size: 2rem; color: red">Intenta subir nuevamente la INE</span>
              <br />
              <base-button
                slot="title"
                type="primary"
                class="btn"
                style="
                  background-color: #d3157b;
                  border-color: #d3157b;
                  color: #ffff;
                  width: 60%;
                  border-radius: 10px;
                  margin-top: 1rem;
                "
                size="xl"
                v-on:click="closeModalA()"
                >Cerrar</base-button
              >
            </div>
            <div
              class="text-center text-muted mb-4"
              v-if="!errorOCR && !dataError && sesionOCR"
            >
              <h4 class="display-4" style="margin-top: 1rem; font-size: 3rem">
                Error al subir la ine
              </h4>
              <span style="margin-top: 1rem; font-size: 2rem; color: red">Ha caducado la sesión del cliente</span>
              <br />
              <base-button
                slot="title"
                type="primary"
                class="btn"
                style="
                  background-color: #d3157b;
                  border-color: #d3157b;
                  color: #ffff;
                  width: 60%;
                  border-radius: 10px;
                  margin-top: 1rem;
                "
                size="xl"
                v-on:click="redirigirInicio()"
                >Regresar a Inicio</base-button
              >
            </div>
          </template>
        </card>
      </modal>
      <modal
        :show.sync="uploadINEB"
        body-classes="p-0"
        modal-classes="modal-dialog-centered modal-sm"
        class="modal-fluid"
      >
        <card class="border-0">
          <template>
            <div class="text-center text-muted mb-4" v-if="loading">
              <h4 class="display-4" style="margin-top: 1rem; font-size: 2rem">
                Subiendo Identificación Oficial y Analizando datos....
              </h4>
              <br />
              <b-spinner
              class="spinner-border text-warning"
                style="width: 3rem; height: 3rem; margin-top: 1rem"
                label="Large Spinner"
              ></b-spinner>
            </div>
            <div
              class="text-center text-muted mb-4"
              v-if="!loading && dataUsers"
            >
              <h3 class="display-4" style="color: #d3157b">Datos obtenidos:</h3>
              <span style="margin-top: 1rem" v-if="dataUsers.id_number"
                >Número de Identifiación:
                <b>{{ dataUsers.id_number ? dataUsers.id_number : "---" }}</b></span
              >
              <br />
              <base-button
                slot="title"
                type="primary"
                class="btn"
                style="
                  background-color: #d3157b;
                  border-color: #d3157b;
                  color: #ffff;
                  width: 60%;
                  border-radius: 10px;
                  margin-top: 1rem;
                "
                size="xl"
                v-on:click="closeModalB()"
                >{{ "Continuar" }}</base-button
              >
            </div>
            <div
              class="text-center text-muted mb-4"
              v-if="errorOCR && dataError"
            >
              <h4 class="display-4" style="margin-top: 1rem; font-size: 3rem">
                Error al subir la ine
              </h4>
              <span style="margin-top: 1rem; font-size: 2rem; color: red">{{
                dataError.error
              }}</span>
              <br />
              <base-button
                slot="title"
                type="primary"
                class="btn"
                style="
                  background-color: #d3157b;
                  border-color: #d3157b;
                  color: #ffff;
                  width: 60%;
                  border-radius: 10px;
                  margin-top: 1rem;
                "
                size="xl"
                v-on:click="closeModalB()"
                >Cerrar</base-button
              >
            </div>
            <div
              class="text-center text-muted mb-4"
              v-if="errorOCR && !dataError"
            >
              <h4 class="display-4" style="margin-top: 1rem; font-size: 3rem">
                Error al subir la ine
              </h4>
              <span style="margin-top: 1rem; font-size: 2rem; color: red">Intenta subir nuevamente la INE</span>
              <br />
              <base-button
                slot="title"
                type="primary"
                class="btn"
                style="
                  background-color: #d3157b;
                  border-color: #d3157b;
                  color: #ffff;
                  width: 60%;
                  border-radius: 10px;
                  margin-top: 1rem;
                "
                size="xl"
                v-on:click="closeModalB()"
                >Cerrar</base-button
              >
            </div>
            <div
              class="text-center text-muted mb-4"
              v-if="!errorOCR && !dataError && sesionOCR"
            >
              <h4 class="display-4" style="margin-top: 1rem; font-size: 3rem">
                Error al subir la ine
              </h4>
              <span style="margin-top: 1rem; font-size: 2rem; color: red">Ha caducado la sesión del cliente</span>
              <br />
              <base-button
                slot="title"
                type="primary"
                class="btn"
                style="
                  background-color: #d3157b;
                  border-color: #d3157b;
                  color: #ffff;
                  width: 60%;
                  border-radius: 10px;
                  margin-top: 1rem;
                "
                size="xl"
                v-on:click="redirigirInicio()"
                >Regresar a Inicio</base-button
              >
            </div>
          </template>
        </card>
      </modal>
    </div>
  </section>
</template>
<script>
import Dropbox from "../components/Dropbox";
import DropboxResize from "../components/DropboxResize";
import { V } from "@/V";
export default {
  components: {
    Dropbox,
    DropboxResize,
  },
  created() {
    this.phone = JSON.parse(localStorage.getItem("phone"));
    this.session = JSON.parse(localStorage.getItem("session"));
    this.ineA = JSON.parse(localStorage.getItem("INEA"));
    this.ineB = JSON.parse(localStorage.getItem("INEB"));
    if(this.ineA && this.ineB){
      this.getDataMissing();
    }
  },
  data() {
    return {
      dataError: null,
      dataUsers: null,
      loading: false,
      errorOCR: false,
      sesionOCR: false,
      stateOfBirth: null,
      uploadINEA: false,
      uploadINEB: false,
      session: null,
      dataCal: null,
      userFiles: [],
      lore: "Register",
      registerOrNot: false,
      a: false,
      b: false,
      dataMissing: "",
      ineA: null,
      ineB: null,
    };
  },
  computed: {
    mxStates() {
      return this.$store.state.mxStates;
    },
    isEmailValid: function () {
      return !this.signup.email
        ? ""
        : this.regEmail.test(this.signup.email)
        ? "has-success"
        : "has-danger";
    },
    isPhoneValid: function () {
      return !this.signup.phone
        ? ""
        : this.regPhone.test(this.signup.phone)
        ? "has-success"
        : "has-danger";
    },
    getMarketing() {
      return this.$store.state.marketing;
    },
  },
  methods: {
    closeModalA() {
      this.uploadINEA =false;
      this.dataError = null;
      this.dataUsers = null;
      this.loading = false;
      this.errorOCR = false;
      this.getDataMissing();
    },
    closeModalB() {
      this.uploadINEB =false;
      this.dataError = null;
      this.dataUsers = null;
      this.loading = false;
      this.errorOCR = false;
      this.getDataMissing();
    },
    redirigirInicio() {
      this.$router.push("/");
    },
    getDataMissing() {
      console.log('Entro')
      this.ineA = JSON.parse(localStorage.getItem("INEA"));
      this.ineB = JSON.parse(localStorage.getItem("INEB"));
      if(this.ineA && this.ineB){
      this.uploadINEA=false;
      this.uploadINEB =false;
      let url = this.$v.SERVER + this.$v.USER_OPS.GET_DATA_MISSING;
      this.$axios.post(url).then((response) => {
        let data = response.data;
        if (data[this.$v.API.SUCCESS]) {
          this.dataMissing = response.data[this.$v.API.DATA].missingField;
          if (this.dataMissing == null) {
            this.$router.push("/register").catch(() => {});
          } else {
            this.$router.push("/datamissing").catch(() => {});
            window.location.reload();
          }
        }
      });
      }
    },
    async uploadFileTrasera(obj) {
      let file = obj.file;
      let formData = new FormData();
      formData.append("file", file);
      this.uploadINEB = true;
      this.loading = true;
      let bodyURL = {
        filename: obj.file.name,
      };
      let body = {
        filename: obj.file.name,
        type: "ID_B",
      };
      await this.$axios
        .post(V.SERVER + V.USER_OPS.UPLOAD_URL, bodyURL)
        .then((response) => {
          let data = response.data;
          if (response.data.success) {
            let fileURL = data[V.API.DATA].url;
            this.$axios.put(fileURL, file).then((putObjectResponse) => {
              if (putObjectResponse.status === 200) {
                this.$axios
                  .post(V.SERVER + V.USER_OPS.ANALYZE_OCR, body)
                  .then(async (response) => {
                    this.$showLoader(false);
                    if (response.data.success) {
                      this.loading = false;
                      this.dataUsers = response.data.data.userData;
                      for (let i = 0; i < this.mxStates.length; i++) {
                        let st = this.mxStates[i];
                        if (this.dataUsers.state_of_birth === st.code) {
                          this.stateOfBirth = st.state;
                          break;
                        }
                      }
                      this.dataError = null;
                      this.b = true;
                      localStorage.setItem(
                        "INEB",
                        JSON.stringify(this.dataUsers)
                      );
                    } else {
                      this.loading = false;
                      this.errorOCR = true;
                      this.dataUsers = null;
                      this.dataError = response.data.data;
                      this.b = true;
                      localStorage.setItem(
                        "INEB",
                        JSON.stringify(this.dataError)
                      );
                      this.$notify({
                        text: "Error, intenta nuevamente",
                        type: "warn",
                      });
                    }
                  })
                  .catch((e) => {
                    console.log(e);
                    this.loading = false;
                    this.dataError = null;
                    this.errorOCR = true;
                    //alert("No se logró subir, intente nuevamente");
                  });
              } else {
                this.loading = false;
                this.dataError = null;
                this.errorOCR = true;
                this.$notify({
                  text: "No se logró subir la INE",
                  type: "warn",
                });
              }
            });
          } else if(data.reason.code == 50){
            this.loading = false;
            this.dataError = null;
            this.errorOCR = false;
            this.sesionOCR=true;

            this.$notify({
              text: "Sesión Caducada",
              type: "error",
            });
            console.log('Error sesion INE B')
          }else {
           this.loading = false;
            this.dataError = null;
            this.errorOCR = false;
            this.sesionOCR=true;

            this.$notify({
              text: "Sesión Caducada",
              type: "error",
            });
            console.log('Error sesion INE B')
          }
        })
        .catch((e) => {
          console.log(e);
            this.loading = false;
            this.dataError = null;
            this.errorOCR = false;
            this.sesionOCR=true;
            console.log('Error sesion INE B')
          //alert("No se logró subir, intente nuevamente");
        });
    },
    async uploadFileFrente(obj) {
      let file = obj.file;
      let formData = new FormData();
      formData.append("file", file);
      this.uploadINEA = true;
      this.loading = true;
      let bodyURL = {
        filename: obj.file.name,
      };
      await this.$axios
        .post(V.SERVER + V.USER_OPS.UPLOAD_URL, bodyURL)
        .then((response) => {
          let data = response.data;
          if (response.data.success) {
            console.log(data);
            let fileURL = data[V.API.DATA].url;
            this.$axios.put(fileURL, file).then((putObjectResponse) => {
              if (putObjectResponse.status === 200) {
                let body = {
                  filename: obj.file.name,
                  type: "ID",
                };
                this.$axios
                  .post(V.SERVER + V.USER_OPS.ANALYZE_OCR, body)
                  .then(async (response) => {
                    if (response.data.success) {
                      this.loading = false;
                      this.dataUsers = response.data.data.userData;
                      for (let i = 0; i < this.mxStates.length; i++) {
                        let st = this.mxStates[i];
                        if (this.dataUsers.state_of_birth === st.code) {
                          this.stateOfBirth = st.state;
                          break;
                        }
                      }
                      this.dataError = null;
                      this.a = true;
                      localStorage.setItem(
                        "INEA",
                        JSON.stringify(this.dataUsers)
                      );
                    } else {
                      this.loading = false;
                      this.errorOCR = true;
                      this.dataUsers = null;
                      this.dataError = response.data.data;
                      this.a = true;
                      localStorage.setItem(
                        "INEA",  JSON.stringify(this.dataError)
                      );
                      this.$notify({
                        text: "Error, intenta nuevamente",
                        type: "warn",
                      });
                    }
                  })
                  .catch((e) => {
                    this.loading = false;
                    this.errorOCR = true;
                    this.dataError = null;
                    console.log(e);
                    //alert("No se logró subir, intente nuevamente");
                  });
              } else {
                this.loading = false;
                this.dataError = null;
                this.errorOCR = true;
                this.$notify({
                  text: "No se logró subir la INE",
                  type: "warn",
                });
              }
            });
          }else if(data.reason.code == 50){
            this.loading = false;
            this.dataError = null;
            this.errorOCR = false;
            this.sesionOCR=true;

            this.$notify({
              text: "Sesión Caducada",
              type: "error",
            });
            console.log('Error sesion INE A')
          } else {
            this.loading = false;
            this.dataError = null;
            this.errorOCR = false;
            this.sesionOCR=true;

            this.$notify({
              text: "Sesión Caducada",
              type: "error",
            });
            console.log('Error sesion INE A')
          }
        })
        .catch((e) => {
           this.loading = false;
            this.dataError = null;
            this.errorOCR = false;
            this.sesionOCR=true;
            console.log('Error sesion INE A')
          console.log(e);
          //alert("No se logró subir, intente nuevamente");
        });
    },
  },
};
</script>
<style>
@media (min-width: 1400px) {
  .tamaño {
    height: 50rem;
  }
  .tarjeta {
    margin-top: 6rem;
  }
}
</style>
