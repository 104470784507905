<template>
  <div>
    <section>
      <div class="d-block d-sm-none">
        <section class="section section-md">
          <div class="container-grid" id="main-container-desxs-hp">
            <div class="row">
              <div class="col-6">
                <div class="d-flex justify-content-end">
                  <div id="quotexs-hp">
                    <span
                      id="spanxs-hp"
                      style="font-size: 20pt; align-items: center"
                    >
                      Apertura tu cuenta Finamigo
                    </span>
                    <br />
                    <div>
                      <button
                        v-on:click="m_startingUser()"
                        style="
                          width: 10rem;
                          background-color: #d3157b;
                          font-size: 10pt;
                          border-radius: 10px;
                        "
                        class="
                          btn btn-icon
                          text-white
                          nav-link-inner--text
                          init
                        "
                      >
                        APERTURAR CUENTA
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
    <section>
      <div class="d-none d-sm-block d-md-none">
        <section class="section section-md">
          <div class="container-grid" id="main-container-dessm-hp">
            <div class="row">
              <div class="col-6">
                <div class="d-flex justify-content-end">
                  <div id="quotesm-hp">
                    <span
                      id="spansm-hp"
                      style="font-size: 20pt; align-items: center"
                    >
                      Apertura tu cuenta Finamigo
                    </span>
                    <br />
                    <div>
                      <button
                        v-on:click="m_startingUser()"
                        style="
                          width: 10rem;
                          background-color: #d3157b;
                          font-size: 10pt;
                          border-radius: 10px;
                        "
                        class="
                          btn btn-icon
                          text-white
                          nav-link-inner--text
                          init
                        "
                      >
                        APERTURAR CUENTA
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
    <section>
      <div class="d-none d-md-block d-lg-none">
        <section class="section section-md">
          <div class="container-grid" id="main-container-desmd-hp">
            <div class="row">
              <div class="col-6">
                <div class="d-flex justify-content-end">
                  <div id="quotemd-hp">
                    <span
                      id="spanmd-hp"
                      style="font-size: 28pt; align-items: center"
                    >
                      Apertura tu cuenta Finamigo
                    </span>
                    <br />
                    <div>
                      <button
                        v-on:click="m_startingUser()"
                        style="
                          width: 15rem;
                          background-color: #d3157b;
                          font-size: 15pt;
                          border-radius: 10px;
                        "
                        class="
                          btn btn-icon
                          text-white
                          nav-link-inner--text
                          init
                        "
                      >
                        APERTURAR CUENTA
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
    <section>
      <div class="d-none d-lg-block d-xl-none">
        <section class="section section-md">
          <div class="container-grid" id="main-container-deslg-hp">
            <div class="row">
              <div class="col-6">
                <div class="d-flex justify-content-end">
                  <div id="quotelg-hp">
                    <span
                      id="spanlg-hp"
                      style="font-size: 35pt; align-items: center"
                    >
                      Apertura tu cuenta Finamigo
                    </span>
                    <br />
                    <div>
                      <button
                        v-on:click="m_startingUser()"
                        style="
                          width: 20rem;
                          background-color: #d3157b;
                          font-size: 20pt;
                          border-radius: 10px;
                        "
                        class="
                          btn btn-icon
                          text-white
                          nav-link-inner--text
                          init
                        "
                      >
                        APERTURAR CUENTA
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
    <section>
      <div class="d-none d-xl-block">
        <section class="section section-md">
          <div class="container-grid" id="main-container-desxl-hp">
            <div class="row">
              <div class="col-6">
                <div class="d-flex justify-content-end">
                  <div id="quotexl-hp">
                    <span
                      id="spanxl-hp"
                      style="font-size: 40pt; align-items: center"
                    >
                      Apertura tu cuenta Finamigo
                    </span>
                    <br />
                    <div>
                      <button
                        v-on:click="m_startingUser()"
                        style="
                          width: 20rem;
                          background-color: #d3157b;
                          font-size: 20pt;
                          border-radius: 10px;
                        "
                        class="
                          btn btn-icon
                          text-white
                          nav-link-inner--text
                          init
                        "
                      >
                        APERTURAR CUENTA
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
    <modal
      :show.sync="PreUserModal"
      body-classes="p-0"
      modal-classes="modal-dialog-centered modal-sm"
    >
      <card
        shadow
        header-classes="bg-white pb-5"
        body-classes="px-lg-5 py-lg-5"
        style="background-color: #ffff; opacity: 1 !important"
        class="border-0"
      >
        <template>
          <div class="text-center text-muted mb-4">
            <span>Ingresa los siguientes datos para aperturar tu cuenta</span>
          </div>
          <form role="form">
            <base-input
              alternative
              type="Phone"
              v-model="preUser.phone"
              :valid="m_hasValidPhone()"
              placeholder="Teléfono"
            ></base-input>
            <div v-if="PreUserSent" class="text-center text-muted mb-4">
              <small
                >Para poder continuar te envíamos un SMS con un nip, por favor
                ingresalo a continuación.</small
              >
            </div>
            <base-input
              alternative
              v-model="PreUserValidationCode"
              v-if="PreUserSent"
              :valid="PreUserValidationCode.length === 5"
              placeholder="Código de verificación"
            ></base-input>
            <div class="text-center">
              <base-button
                type="primary"
                class="my-6"
                size="sm"
                v-on:click="m_insertUser()"
                v-bind:disabled="!m_hasValidPhone()"
                >{{ PreUserSent ? "Continuar" : "Enviar NIP" }}</base-button
              >
            </div>
          </form>
        </template>
      </card>
    </modal>
  </div>
</template>
<script>
import Vue from "vue";
import modal from "@/components/Modal";
import { V } from "@/V";
import VueFacebookPixel from "vue-analytics-facebook-pixel";
import { LazyYoutube } from "vue-lazytube";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";

Vue.component("VueSlider", VueSlider);

Vue.use(VueFacebookPixel);
export default {
  name: "registerNew",
  components: {
    modal,
    VueFacebookPixel,
    LazyYoutube,
    VueSlider,
  },
  created() {
    localStorage.clear();
  },
  mounted() {
    localStorage.clear();
  },
  data() {
    return {
      V: V,
      disabled: true,
      preUser: {
        phone: "",
      },
      PreUserValidationCode: "",
      PreUserSent: false,
      sameBankDebtPaysheet: false,
      PreUserModal: false,
    };
  },
  methods: {
    m_startingUser() {
      this.PreUserModal = true;
    },
    m_hasValidPhone() {
      return (
        !isNaN(this.preUser.phone) &&
        this.preUser.phone.length > 8 &&
        this.preUser.phone.length < 11
      );
    },
    m_insertUser() {
      if (this.PreUserSent) {
        let _data = {
          phone: this.preUser.phone,
          authCode: this.PreUserValidationCode,
        };
        let url = V.SERVER + V.PUBLIC_OPS.LOGIN;
        this.$http.post(url, _data).then((res) => {
          if (res.data.success === true) {
            this.$notify({
              text: "NIP Validado",
              type: "success",
            });
            this.PreUserModal = false;
            this.PreUserSent = false;
            let progress = res.data.data.progress;

            localStorage.setItem("phone", JSON.stringify(_data));
            localStorage.setItem("session", JSON.stringify(res.data.data));

            if (
              progress.official_id == 1 &&
              progress.basic_information == 1 &&
              progress.basic_information2 == 1 &&
              progress.address_information == 1 &&
              progress.address_file == 1 &&
              progress.beneficiary_information == 1 &&
              progress.other_info == 1 &&
              progress.contract_signature == 1 &&
              progress.contract_file == 1
            ) {
              this.$notify({
                text: "Ya has completado anteriormente, tu registro para aperturar tu cuenta",
                type: "success",
              });
            } else if (progress.official_id == 0) {
              this.$router.push({ path: "/uploadOCR" });
              window.location.reload();
            } else if (
              progress.basic_information == 0 ||
              progress.basic_information2 == 0 ||
              progress.address_information == 0
            ) {
              this.$router.push({ path: "/datamissing" });

              window.location.reload();
            } else if (
              progress.address_file == 0 ||
              progress.beneficiary_information == 0 ||
              progress.other_info == 0
            ) {
              this.$router.push({ path: "/register" });

              window.location.reload();
            } else if (progress.contract_signature == 0) {
              let headers = {
                user: res.data.data.user,
              };
              this.$axios
                .post(V.SERVER + V.USER_OPS.REQUEST_SIGNATURE_TOKEN, {
                  headers: headers,
                })
                .then(async (response) => {
                  this.$showLoader(false);
                  if (response.data.success) {
                    this.token = response.data.data.token;
                    this.url = response.data.data.urlFile;
                    localStorage.setItem("urlFile", JSON.stringify(this.url));
                    this.$notify({
                      text: "Información Guardada Correctamente",
                      type: "success",
                    });

                    this.$router.push({
                      path: "/signature?tkFA=" + this.token,
                    });
                  } else {
                    this.$showLoader(false);
                    this.$router.push({ path: "/register" });
                  }
                });
            }
            console.log(res.data.data.progress);
            //this.$router.push({ path: "/uploadOCR" });
          } else {
            this.$notify({
              text: "Algo salió mal",
              type: "error",
            });
          }
        });
      } else {
        this.PreUserSent = true;
        let _data = {
          phone: this.preUser.phone,
        };
        let url = V.SERVER + V.PUBLIC_OPS.REQUEST_AUTH_CODE;
        this.$http.post(url, _data).then((res) => {
          if (res.data.success) {
            localStorage.setItem("phone", JSON.stringify(_data));
            this.$notify({
              text: "Se envío nip por SMS",
              type: "success",
            });
            this.preUserSent = res.data.success;
          } else {
            this.$notify({
              text: "Algo salió mal",
              type: "error",
            });
          }
        });
      }
    },
  },
};
</script>

<style>
.custom-dot {
  padding: 20%;
  margin-top: -0.5rem;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #eab740;
  transition: all 0.3s;
  animation: pulse2 2s infinite;
}
.custom-dot:hover {
  animation: pulse2 0s infinite;
}
.custom-dot.focus {
  animation: pulse2 0s infinite;
}
@font-face {
  font-family: bahnschrift;
  src: url(/fonts/Bahnschrift_font/bahnschrift.ttf);
}
@font-face {
  font-family: Roboto-ThinItalic;
  src: url(/fonts/Roboto_font/Roboto-ThinItalic.ttf);
}
@font-face {
  font-family: Roboto-Thin;
  src: url(/fonts/Roboto_font/Roboto-Thin.ttf);
}
@font-face {
  font-family: Roboto-Regular;
  src: url(/fonts/Roboto_font/Roboto-Regular.ttf);
}
@font-face {
  font-family: Roboto-MediumItalic;
  src: url(/fonts/Roboto_font/Roboto-MediumItalic.ttf);
}
@font-face {
  font-family: Roboto-Medium;
  src: url(/fonts/Roboto_font/Roboto-Thin.ttf);
}
@font-face {
  font-family: Roboto-LightItalic;
  src: url(/fonts/Roboto_font/Roboto-LightItalic.ttf);
}
@font-face {
  font-family: Roboto-Light;
  src: url(/fonts/Roboto_font/Roboto-Light.ttf);
}
@font-face {
  font-family: Roboto-Italic;
  src: url(/fonts/Roboto_font/Roboto-Italic.ttf);
}
@font-face {
  font-family: Roboto-BoldItalic;
  src: url(/fonts/Roboto_font/Roboto-BoldItalic.ttf);
}
@font-face {
  font-family: Roboto-Bold;
  src: url(/fonts/Roboto_font/Roboto-Bold.ttf);
}
@font-face {
  font-family: Roboto-BlackItalic;
  src: url(/fonts/Roboto_font/Roboto-BlackItalic.ttf);
}
@font-face {
  font-family: Roboto-Black;
  src: url(/fonts/Roboto_font/Roboto-Black.ttf);
}
@font-face {
  font-family: Gotham-Black;
  src: url(/fonts/Gotham_Font/Gotham-Black.otf);
}
@font-face {
  font-family: Gotham-Thin;
  src: url(/fonts/Gotham_Font/Gotham-Thin.otf);
}
@font-face {
  font-family: Gotham-ThinItalic;
  src: url(/fonts/Gotham_Font/Gotham-ThinItalic.otf);
}
@font-face {
  font-family: Gotham-ultra;
  src: url(/fonts/Gotham_Font/gotham-ultra.ttf);
}
@font-face {
  font-family: Gotham-UltraItalic;
  src: url(/fonts/Gotham_Font/Gotham-UltraItalic.otf);
}
@font-face {
  font-family: Gotham-Bold;
  src: url(/fonts/Gotham_Font/GothamBold.ttf);
}
@font-face {
  font-family: Gotham-BoldItalic;
  src: url(/fonts/Gotham_Font/GothamBoldItalic.ttf);
}
@font-face {
  font-family: Gotham-Book;
  src: url(/fonts/Gotham_Font/GothamBook.ttf);
}
@font-face {
  font-family: Gotham-BookItalic;
  src: url(/fonts/Gotham_Font/GothamBookItalic.ttf);
}
@font-face {
  font-family: Gotham-Light;
  src: url(/fonts/Gotham_Font/GothamLight.ttf);
}
@font-face {
  font-family: Gotham-Medium;
  src: url(/fonts/Gotham_Font/GothamMedium.ttf);
}
@font-face {
  font-family: Gotham-LightItalic;
  src: url(/fonts/Gotham_Font/GothamLightItalic.ttf);
}
@font-face {
  font-family: Metropolis-ExtraLight;
  src: url(/fonts/Metropolis/Metropolis-ExtraLight.otf);
}
@font-face {
  font-family: HomePage-Book;
  src: url(/fonts/HomePageBaukasten/HomepageBaukasten-Book.ttf);
}
@font-face {
  font-family: HomePage-Bold;
  src: url(/fonts/HomePageBaukasten/HomepageBaukasten-Bold.ttf);
}

/*XS*/
#main-container-desxs-hp {
  position: relative;
  height: 100vh;
  padding: 10%;
  widows: 110%;
  margin-left: -25%;
  background-color: #e9edee;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://dbmenos.com/img/finamigo/home3.jpg");
}
#quotexs-hp {
  position: absolute;
  padding: 4%;
  opacity: 0.85;
  color: #000;
  background-color: rgb(233, 237, 238);
  text-align: center;
  width: 120%;
  margin-top: 180%;
  left: 60%;
}
#spanxs-hp {
  font-family: Metropolis-ExtraLight;
  font-size: 80%;
  text-align: left;
}

/*SM*/
#main-container-dessm-hp {
  height: 100vh;
  padding: 10%;
  widows: 110%;
  margin-left: -12rem;
  background-color: #ebebeb;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://dbmenos.com/img/finamigo/home3.jpg");
}
#quotesm-hp {
  position: absolute;
  color: #808080;
  text-align: center;
  width: 100%;
  margin-top: 20%;
  left: 110%;
}
#spansm-hp {
  font-family: Metropolis-ExtraLight;
  font-size: 80%;
  text-align: left;
}

/*MD*/
#main-container-desmd-hp {
  height: 100vh;
  padding: 10%;
  widows: 110%;
  margin-left: -12rem;
  background-color: #ebebeb;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://dbmenos.com/img/finamigo/home3.jpg");
}
#quotemd-hp {
  position: absolute;
  color: #808080;
  text-align: center;
  width: 100%;
  margin-top: 10%;
  left: 110%;
}
#spanmd-hp {
  font-family: Metropolis-ExtraLight;
  font-size: 80%;
  text-align: left;
}

/*LG*/
#main-container-deslg-hp {
  height: 100vh;
  padding: 20%;
  margin-left: -12rem;
  background-color: #ebebeb;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://dbmenos.com/img/finamigo/home3.jpg");
}
#quotelg-hp {
  position: absolute;
  padding: 5%;
  color: #808080;
  text-align: center;
  width: 120%;
  top: 8em;
  left: 120%;
}
#spanlg-hp {
  font-family: Metropolis-ExtraLight;
  font-size: 100%;
  text-align: left;
}

/*XL*/
#main-container-desxl-hp {
  height: 100vh;
  width: 110%;
  padding: 22%;
  margin-left: -4rem;
  background-color: #ebebeb;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://dbmenos.com/img/finamigo/home3.jpg");
}
#quotexl-hp {
  position: absolute;
  padding: 5%;
  color: #808080;
  text-align: center;
  width: 120%;
  top: -2rem;
  left: 100%;
}
#spanxl-hp {
  font-family: Metropolis-ExtraLight;
  font-size: 100%;
  text-align: left;
}
</style>
