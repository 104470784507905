import Vue from "vue";
import Router from "vue-router";

import AppHeader from "@/layout/AppHeader";

import SignatureFinamigo from "@/views/SignatureFinamigo.vue";
import Home from "@/views/Home.vue";
import Register from "@/views/Register.vue";
import OCR from "@/views/OCR.vue";
import DataMissing from "@/views/DataMissingField.vue";
import FinishPage from "@/views/FinishPage.vue";

import { V } from "./V"

Vue.use(Router);

//const AppHeader = localStorage.getItem(V.STORE_KEYS.SESSION) ? AuthAppHeader : AppHeader

const router = new Router({
    mode: 'history',
    linkActiveClass: 'active',
    routes: [
        {
            path: "/signature",
            name: "signature",
            components: {
                default: SignatureFinamigo
            }
        },
        {
            path: "/finish",
            name: "finish",
            components: {
                header: AppHeader,
                default: FinishPage
            }
        },
        {
            path: "/register",
            name: "register",
            components: {
                header: AppHeader,
                default: Register
            },
            beforeEnter: (to, from, next) => {
                let sess = null
                let sessionData = JSON.parse(localStorage.getItem(V.STORE_KEYS.SESSION))
                if(sessionData) { 
                    sess = localStorage.getItem(V.STORE_KEYS.SESSION) }
                if (!sess) {
                    next('/')
                    return
                } else {
                    next()
                }
            }
        },
        {
            path: "/uploadOCR",
            name: "uploadOCR",
            components: {
                header: AppHeader,
                default: OCR
            },
            beforeEnter: (to, from, next) => {
                let sess = null
                let sessionData = JSON.parse(localStorage.getItem(V.STORE_KEYS.SESSION))
                if(sessionData) { 
                    sess = localStorage.getItem(V.STORE_KEYS.SESSION) }
                if (!sess) {
                    next('/')
                    return
                } else {
                    next()
                }
            }
        },
        {
            path: "/datamissing",
            name: "Datamissing",
            components: {
                header: AppHeader,
                default: DataMissing
            },
            beforeEnter: (to, from, next) => {
                let sess = null
                let sessionData = JSON.parse(localStorage.getItem(V.STORE_KEYS.SESSION))
                if(sessionData) { 
                    sess = localStorage.getItem(V.STORE_KEYS.SESSION) }
                if (!sess) {
                    next('/')
                    return
                } else {
                    next()
                }
            }
        },
        {
            path: "/",
            name: "home",
            components: {
                header: AppHeader,
                default: Home
            }
        },
    ],
    scrollBehavior: to => {
        if (to.hash) {
            return { selector: to.hash };
        } else {
            return { x: 0, y: 0 };
        }
    }
});

router.beforeEach((to, from, next) => {
    let session = localStorage.getItem(V.STORE_KEYS.SESSION)
    if (to.meta.requiresAuth && (session === undefined || session === null)) {
        next('/')
    } else {
        next()
    }
});

export default router;