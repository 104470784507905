export let V = Object.freeze({
    //SERVER: "http://localhost:1025", // LOCAL
    SERVER: "https://7pah6ppvu1.execute-api.us-west-1.amazonaws.com/latest", // PROD
    CURRENT_VERSION: "9.4",
    STORE_KEYS: {
        GENERAL_INFO: "generalInfo",
        BANKS: "banks",
        SESSION: "session",
        USER: "user",
        PHONE: "phone",
        SET_SHOW_LOADER: "setShowLoader"
    },
    USER_OPS: {
        UPLOAD_URL: '/user/uploadUrl',
        ANALYZE_OCR: '/user/analyzeOCR',
        SET_FILE: '/user/setFile',
        REQUEST_SIGNATURE_TOKEN: '/user/requestSignatureToken',
        SET_BENEFICIARY:'/user/setBeneficiary',
        SET_USER:'/user/set',
        GET_USER:'/user/getUser',
        GET_DATA_MISSING:'/user/getDataMissing'
    },
    PUBLIC_OPS: {
        REQUEST_AUTH_CODE: '/public/requestAuthCode',
        LOGIN: '/public/login',
        SET_FINAMIGO_SIGNATURE: '/public/setFinamigoSignature',
        VALIDATE_SIGNATURE_FA_TOKEN: '/public/validateSignatureFaToken',
        SET_GEOLOCATION:'/public/setGeoLocation'
    },
    CATALOG_OPS: {
        ECONOMIC_ACTIVITIES: '/catalog/getEconomicActivities',
        RELATIONSHIP_BENEFICIARY: '/catalog/getRelationshipBeneficiary',
        MX_SATES: '/catalog/getMxStates',
        POSTAL_CODES: '/catalog/getPostalCodes',
    },
    API: {
        SUCCESS: "success",
        ROWS: "rows",
        ROW: "row",
        DATA: "data",
        REASON: "reason",
    },
    TERMS_CONDITIONS: "https://s3-us-west-1.amazonaws.com/dbmenos/documentos/TERMINOS+Y+CONDICIONES+GRUPO+DB+MENOS+2018.pdf",
    PRIVACY_NOTICE: "https://s3-us-west-1.amazonaws.com/dbmenos/documentos/AVISO+DE+PRIVACIDAD+GRUPO+DB+MENOS+2018.pdf",
    FACEBOOK_PIXEL_ID: "175290776628728"
})