import Vue from 'vue'
import Vuex from 'vuex'
import {V} from './V'

import axios from "axios";

axios.interceptors.request.use(config => {
    let session = localStorage.getItem(V.STORE_KEYS.SESSION)
    if (session) {
        config.headers.common[V.STORE_KEYS.SESSION] = session
        config.headers.common['device'] = 'web'
    }
    return config
}, error => {
    console.log(error)
})

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        axios: axios,
        showLoader: false,
        economicActivities: [],
        relationBeneficiary: [],
        mxStates: [],
    },
    mutations: {
        setShowLoader(state, value) {state.showLoader = value},
        setEconomicActivities(state, value) {state.economicActivities = value},
        setRelationBeneficiary(state, value) { state.relationBeneficiary = value },
        setMxStates(state, value) { state.mxStates = value },
    },
    getters: {
        getShowLoader(state) {return state.showLoader},
        getRelationBeneficiary(state) { return state.relationBeneficiary },
        getEconomicActivities(state) {return state.economicActivities},
        getMxStates(state) { return state.mxStates },
    },
    actions: {
        showLoader({commit}) {
            commit('setShowLoader', true)
        },
        hideLoader({commit})
        {
            commit('setShowLoader', false)
        },
        async downloadEconomicActivities({
            commit
        }) {
            let url = V.SERVER + V.CATALOG_OPS.ECONOMIC_ACTIVITIES;
            axios.post(url).then(response => {
                if (response.data[V.API.SUCCESS]) {
                    commit('setEconomicActivities', response.data[V.API.DATA])
                } else {
                    console.log("error d_economicActivities")
                }
            })
        },
        async downloadRelationBeneficiary({
            commit
        }) {
            let url = V.SERVER + V.CATALOG_OPS.RELATIONSHIP_BENEFICIARY;
            axios.post(url).then(response => {
                if (response.data[V.API.SUCCESS]) {
                    commit('setRelationBeneficiary', response.data[V.API.DATA])
                } else {
                    console.log("error d_relationBeneficiary")
                }
            })
        },
        async downloadMxStates({
            commit
        }) {
            let url = V.SERVER + V.CATALOG_OPS.MX_SATES;
            axios.post(url).then(response => {
                if (response.data[V.API.SUCCESS]) {
                    commit('setMxStates', response.data[V.API.DATA])
                } else { console.log("error setMxStates") }
            })
        },
    }
})

export default store