<template>
  <div class="position-relative">
    <section class="section section-lg pt-lg-0">
      <div
        class="container mt--4"
        style="padding: 5rem"
      >
        <div class="col-lg-12 text-center"></div>
        <div class="row">
          <div class="col-lg-12 center">
            <card style="background-color: #ffff; border: none">
              <template>
                <form @submit.prevent>
                  <div class="pl-lg-12 text-center">
                    <div class="row">
                      <div class="col-lg-12 text-center">
                        <div class="form-check text-center">
                          <div class="row">
                            <div class="col-lg-6">
                              <b-form-group v-slot="{ ariaDescribedby }">
                                <span style="font-size: 1.5rem"
                                  >Estado civil:
                                </span>
                                <b-form-checkbox-group
                                  v-model="client.civil_status"
                                  :options="options"
                                  :aria-describedby="ariaDescribedby"
                                  buttons
                                  style="margin-left: 5%"
                                  button-variant="success"
                                  size="lg"
                                  name="buttons-2"
                                ></b-form-checkbox-group>
                              </b-form-group>
                            </div>
                            <div class="col-6" style="margin-top: -2rem">
                              <span style="font-size: 1.5rem"
                                >¿A qué te dedicas?</span
                              >
                              <base-dropdown
                                style="margin-bottom: 3%; margin-left: 5%"
                              >
                                <base-button
                                  slot="title"
                                  type="success"
                                  class="dropdown-toggle"
                                  size="lg"
                                  style="white-space: normal"
                                  :class="
                                    client.economic_activity === null
                                      ? 'text-danger'
                                      : ''
                                  "
                                  >{{
                                    client.economic_activity
                                      ? getEconomicActivity()
                                      : "Actividad económica"
                                  }}</base-button
                                >
                                <a
                                  class="dropdown-item"
                                  v-for="(
                                    activity, index
                                  ) in economicActivities"
                                  :key="index"
                                  v-on:click="setEconomicActivity(activity.id)"
                                  >{{ activity.activity }}</a
                                >
                              </base-dropdown>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </template>
              <template>
                <form @submit.prevent>
                  <!--
                  <div class="container pt-lg-md-3" v-if="!a && !address">
                    <div class="row justify-content-center">
                      <div
                        class="col-3"
                        style="
                          text-align: center;
                          margin-top: 3.5%;
                          text-transform: uppercase;
                        "
                      >
                        <label>Sube tu comprobante de domicilio</label>
                      </div>
                      <div class="col">
                        <dropbox-resize
                          text
                          type="ADDRESS"
                          :userid="this.client.id"
                          :files="this.userFiles"
                          v-on:uploadFile="uploadFileComprobante"
                          v-on:resentUploadFile="uploadFileComprobante"
                        ></dropbox-resize>
                      </div>
                    </div>
                  </div>
                  !-->

                    <div class="row" style="padding:1rem;">
                      <div class="col-lg-4">
                                  <base-input
                                    alternative
                                    label="País de nacimiento"
                                    input-classes="form-control-alternative"
                                    v-model="client.country_birth"
                                  />
                                </div>
                                <div class="col-lg-4">
                                  <base-input
                                    alternative
                                    label="Nacionalidad"
                                    input-classes="form-control-alternative"
                                    v-model="client.nationality"
                                  />
                                </div>
                                <div class="col-lg-4">
                                  <base-input
                                    alternative
                                    label="Ocupación"
                                    input-classes="form-control-alternative"
                                    v-model="client.occupation"
                                  />
                                </div>
                    </div>

                  <div class="col" v-if="client.civil_status == 'M'">
                  <label>Tipo de régimen conyugal:</label>
                  <div class="row text-center" style="margin-top: 1rem">
                    <div class="col-6">
                      <b-form-checkbox
                        v-model="client.matrimonial_regime"
                        value="Separados"
                        >Separados</b-form-checkbox
                      >
                    </div>
                    <div class="col-6">
                      <b-form-checkbox
                        v-model="client.matrimonial_regime"
                        value="Mancomunados"
                        >Mancomunados</b-form-checkbox
                      >
                    </div>
                  </div>
                </div>
                  <div class="text-center">
                     <hr class="my-4" />
                     <h1 style="color: #d3157b; font-size: 1.5rem; margin-top:5%" class="text-black">Con Finamigo tu cuenta está segura. <br> Dinos quien será el beneficiario de tu cuenta </h1>
                    <div class="text-center" style="margin-top: 5%">
                      <template>
                        <form @submit.prevent>
                          <div class="pl-lg-12">
                            <div class="pl-lg-4">
                              <div class="row">
                                <div class="col-lg-4">
                                  <base-input
                                    alternative
                                    label="Nombre"
                                    input-classes="form-control-alternative"
                                    v-model="beneficiary.name"
                                  />
                                </div>
                                <div class="col-lg-4">
                                  <base-input
                                    alternative
                                    label="Primer apellido"
                                    input-classes="form-control-alternative"
                                    v-model="beneficiary.lastname_1"
                                  />
                                </div>
                                <div class="col-lg-4">
                                  <base-input
                                    alternative
                                    label="Segundo apellido"
                                    input-classes="form-control-alternative"
                                    v-model="beneficiary.lastname_2"
                                  />
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-lg-3">
                                  <label class="form-control-label"
                                    >Fecha de nacimiento</label
                                  >
                                  <flat-pickr
                                    v-model="beneficiary.birthday"
                                    class="
                                      form-control form-control-alternative
                                    "
                                    data-vv-as="Fecha"
                                    name="date"
                                    style="background-color: #ffffff"
                                  />
                                </div>
                                <div class="col-lg-3 text-center">
                                  <base-dropdown style="margin-top: 25px">
                                    <base-button
                                      block
                                      slot="title"
                                      type="success"
                                      size="lg"
                                      class="dropdown-toggle"
                                      >{{
                                        beneficiary.gender
                                          ? beneficiary.gender == "M"
                                            ? "Masculino"
                                            : "Femenino"
                                          : "Selecciona género"
                                      }}</base-button
                                    >
                                    <a
                                      class="dropdown-item"
                                      v-on:click="
                                        setBeneficiaryGender('Masculino')
                                      "
                                      >Masculino</a
                                    >
                                    <a
                                      class="dropdown-item"
                                      v-on:click="
                                        setBeneficiaryGender('Femenino')
                                      "
                                      >Femenino</a
                                    >
                                  </base-dropdown>
                                </div>
                                <div class="col-lg-3 text-center">
                                  <base-dropdown style="margin-top: 25px">
                                    <base-button
                                      block
                                      slot="title"
                                      type="success"
                                      size="lg"
                                      class="dropdown-toggle"
                                      large="true"
                                      >{{
                                        beneficiary.civil_status
                                          ? beneficiary.civil_status == "M"
                                            ? "CASADO"
                                            : "SOLTERO"
                                          : "Estado civil"
                                      }}</base-button
                                    >
                                    <a
                                      class="dropdown-item"
                                      v-on:click="
                                        beneficiaryCivilStatusSelected('Casado')
                                      "
                                      >Casado</a
                                    >
                                    <a
                                      class="dropdown-item"
                                      v-on:click="
                                        beneficiaryCivilStatusSelected(
                                          'Soltero'
                                        )
                                      "
                                      >Soltero</a
                                    >
                                  </base-dropdown>
                                </div>
                                <div class="col-lg-3 text-center">
                                  <base-dropdown style="margin-top: 25px">
                                    <base-button
                                      slot="title"
                                      type="success"
                                      class="dropdown-toggle"
                                      large="true"
                                      size="lg"
                                      :class="
                                        beneficiary.relationship === null
                                          ? 'text-danger'
                                          : ''
                                      "
                                      >{{
                                        beneficiary.relationship
                                          ? getRelationBeneficiary()
                                          : "Parentesco"
                                      }}</base-button
                                    >
                                    <a
                                      class="dropdown-item"
                                      v-for="(
                                        value, index
                                      ) in relationBeneficiary"
                                      :key="index"
                                      v-on:click="
                                        beneficiaryRelationSelected(value.id)
                                      "
                                      >{{ value.value }}</a
                                    >
                                  </base-dropdown>
                                </div>
                              </div>
                            </div>
                            <hr class="my-4" />
                          </div>
                        </form>
                      </template>
                    </div>
                  </div>
                  <div class="row d-flex justify-content-end">
                    <div class="col-6 text-right my-2">
                      <base-button
                        slot="title"
                        type="primary"
                        class="btn"
                        style="
                          background-color: #2b8c9c;
                          border-color: #2b8c9c;
                          color: #ffff;
                          width: 60%;
                          border-radius: 10px;
                        "
                        size="xl"
                        v-on:click="finalCheck"
                        >Guardar</base-button
                      >
                    </div>
                  </div>
                </form>
              </template>
            </card>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import BaseDropdown from "../components/BaseDropdown";
import UploadFilesForm from "@/views/components/UploadFilesForm.vue";
import Dropbox from "../components/Dropbox";
import DropboxResize from "../components/DropboxResize";
import { V } from "../V";

export default {
  data() {
    return {
      session: null,
      urlLocal: null,
      phone: null,
      a: false,
      url: null,
      token:null,
      rows: 100,
      options: [
        { text: "Casado", value: "M" },
        { text: "Soltero", value: "S" },
      ],
      client: {},
      pag: 1,
      gender: null,
      stateOfBirth: null,
      postalCodes: [],
      locality: "",
      sublocalities: [],
      beneficiary: {},
      genderBeneficiary: null,

      pointer: "pointer",
      pointer2: "default",
      regEmail:
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      regPhone: /[0-9]{10}?/,
      payday: 15,
      userFiles: [],
      filetype: null,
      archivo: null,
      files: {
        ADDRESS: null,
      },
      fileId: true,
      fileId_b: true,
      urlAsesor: "",
      phone: "",
      address:null
    };
  },
  created() {
    this.phone = JSON.parse(localStorage.getItem("phone"));
    this.session = JSON.parse(localStorage.getItem("session"));
    //this.address = JSON.parse(localStorage.getItem("ADDRESS"));
    this.getClientProfile();
  },
   computed: {
        relationBeneficiary() {
            return this.$store.state.relationBeneficiary;
        },
        economicActivities() {
            let activities = this.$store.state.economicActivities;
            if (!activities) this.$store.dispatch("downloadEconomicActivities");
            return activities;
        },
    },
  methods: {
     getClientProfile() {
      let url = this.$v.SERVER + this.$v.USER_OPS.GET_USER;
      this.$axios.post(url).then((response) => {
        let data = response.data;
        if (data[this.$v.API.SUCCESS]) {
          this.client = response.data[this.$v.API.DATA];
        }
      });
    },
    getRelationBeneficiary() {
      for (let i = 0; i < this.relationBeneficiary.length; i++)
        if (this.relationBeneficiary[i].id === this.beneficiary.relationship)
          return this.relationBeneficiary[i].value;
      return null;
    },
    beneficiaryRelationSelected(relation) {
      this.beneficiary.relationship = relation;
      this.getRelationBeneficiary();
      this.$forceUpdate();
    },
    finalCheck() {
      this.$forceUpdate();
      if (
        this.client.civil_status &&
        this.client.economic_activity &&
        this.client.country_birth &&
        this.client.nationality &&
        this.client.occupation &&
        this.beneficiary.name &&
        this.beneficiary.lastname_1 &&
        this.beneficiary.lastname_2 &&
        this.beneficiary.birthday &&
        this.beneficiary.gender &&
        this.beneficiary.civil_status
      ) {
       this.$axios
        .post(V.SERVER + V.USER_OPS.REQUEST_SIGNATURE_TOKEN)
        .then(async (response) => {
          this.$showLoader(false);
          if (response.data.success) {
           this.token = response.data.data.token;
           this.url = response.data.data.urlFile;
           localStorage.setItem('urlFile', JSON.stringify(this.url))
            this.set_beneficiary();
            this.updateClientProfile();
            this.$notify({
              text: "Información Guardada Correctamente",
              type: "success",
            });
            
            this.$router.push({
          path: "/signature?tkFA=" + this.token,
        });
          } else {
            this.$showLoader(false);
            this.$notify({
              text: "Error, intenta nuevamente",
              type: "error",
            });
          }
        });
      } else {
        this.$notify({
              text: "Error, intenta nuevamente",
              type: "error",
            });
      }
    },
    updateClientProfile() {
    let url = this.$v.SERVER + this.$v.USER_OPS.SET_USER;
      this.$axios.post(url, this.client).then((response) => {
        let data = response.data;
        if (data[this.$v.API.SUCCESS]) {
        }
      });
    },
    async set_beneficiary() {
        let url = this.$v.SERVER + this.$v.USER_OPS.SET_BENEFICIARY;
        let response = await this.$axios.post(url, this.beneficiary);
        let data = response.data;
        if (data[this.$v.API.SUCCESS]) {
          console.log('Todo beneficiario')
        }
    },
    setBeneficiaryGender(gender) {
      this.beneficiary.gender = gender.substring(0, 1).toUpperCase();
      this.$forceUpdate();
    },
    beneficiaryCivilStatusSelected(civiStatus) {
      if (civiStatus == "Soltero") this.beneficiary.civil_status = "S";
      else if (civiStatus == "Casado") this.beneficiary.civil_status = "M";
      this.$forceUpdate();
    },
    getStatusBeneficiary(status) {
      if (status == "S" || status == "Soltero") {
        return "Soltero";
      } else if (status == "M" || status == "Casado") {
        return "Casado";
      }
      this.$forceUpdate();
    },
    setEconomicActivity(activity) {
      this.client.economic_activity = activity;
      this.$forceUpdate();
    },
    getEconomicActivity() {
      for (let i = 0; i < this.economicActivities.length; i++)
        if (this.economicActivities[i].id === this.client.economic_activity)
          return this.economicActivities[i].activity;
      return null;
    },
    clientCivilStatusSelected(civiStatus) {
      if (civiStatus == "Soltero") this.client.civil_status = "Soltero";
      else if (civiStatus == "Casado") this.client.civil_status = "Casado";
      this.$forceUpdate();
    },
    async uploadFileComprobante(obj) {
      let file = obj.file;
      let formData = new FormData();
      formData.append("file", file);
      this.$showLoader(true);
      let bodyURL = {
        filename: obj.file.name,
      };
      let body = {
        filename: obj.file.name,
        type: "ADDRESS",
      };
      await this.$axios
        .post(V.SERVER + V.USER_OPS.UPLOAD_URL, bodyURL)
        .then((response) => {
          let data = response.data;
          if (response.data.success) {
            let fileURL = data[V.API.DATA].url;
            this.$axios.put(fileURL, file).then((putObjectResponse) => {
              if (putObjectResponse.status === 200) {
                this.$notify({
                  text: "Comprobante Cargado Correctamente",
                  type: "success",
                });
                this.$axios
                  .post(V.SERVER + V.USER_OPS.SET_FILE, body)
                  .then(async (response) => {
                    this.$showLoader(false);
                    if (response.data.success) {
                      let user = true;
                      localStorage.setItem(
                        "ADDRESS",
                        JSON.stringify(user)
                      );
                      this.a = true;
                    } else {
                      this.$showLoader(false);
                      this.$notify({
                        text: "Error, intenta nuevamente",
                        type: "warn",
                      });
                    }
                  })
                  .catch((e) => {
                    console.log(e);
                    this.$showLoader(false);
                    //alert("No se logró subir, intente nuevamente");
                  });
              } else {
                this.$showLoader(false);
                this.$notify({
                  text: "No se logró subir el Comprobante",
                  type: "warn",
                });
              }
            });
          } else {
            this.$showLoader(false);
            this.$notify({
              text: "Error, intenta nuevamente",
              type: "error",
            });
          }
        })
        .catch((e) => {
          console.log(e);
          this.$showLoader(false);
          //alert("No se logró subir, intente nuevamente");
        });
    },
  },
  components: {
    flatPickr,
    BaseDropdown,
    UploadFilesForm,
    Dropbox,
    DropboxResize
  },
};
</script>

<style>
#tit {
  font-weight: 300;
  font-size: large;
}
@media (min-width: 300px) {
  .calcular {
    margin: 5%;
    padding: 6%;
  }
  .calcularbtn {
    margin-left: 1.3rem;
  }
  .separar {
    margin-bottom: 1rem;
  }
  .sta {
    margin-top: -420px;
  }
}
@media (min-width: 370px) {
  .calcular {
    margin: 5%;
    padding: 6%;
  }
  .calcularbtn {
    margin-left: 2.8rem;
  }
  .separar {
    margin-bottom: 1rem;
  }
  .sta {
    margin-top: -420px;
  }
}
@media (min-width: 420px) {
  .calcular {
    margin: 5%;
    padding: 6%;
  }
  .calcularbtn {
    margin-left: 4.2rem;
  }
  .separar {
    margin-bottom: 1rem;
  }
  .sta {
    margin-top: -420px;
  }
}
@media (min-width: 768px) {
  .calcular {
    margin: 5%;
    padding: 3%;
    align-content: center;
  }
  .calcularbtn {
    margin-left: 13.5rem;
  }

  .sta {
    margin-top: -410px;
  }
}

@media (min-width: 992px) {
  .calcular {
    margin-left: 70%;
  }
  .calcularbtn {
    margin-left: 20.5rem;
  }
}

@media (min-width: 1200px) {
  .calcular {
    margin-left: 70%;
  }
  .calcularbtn {
    margin-left: 23rem;
  }
}
</style>
