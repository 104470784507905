<template>
  <div>
    <section>
      <nav class="navbar navbar-expand-lg navbar-light bg-light text-center">
  <div class="container-fluid">
    <a class="navbar-brand" href="/" style="padding:1rem;">
      <img src="https://dbmenos.com/img/finamigo.png" alt="" width="150" class="d-inline-block align-text-top">
    </a>
    <!--
    <form class="text-center">
        <button style="width: 15rem; background-color: #d3157b"
                      class="btn btn-icon text-white nav-link-inner--text init">APERTURAR CUENTA</button>
      </form>
      !-->
  </div>
</nav>
<!--
            <nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container-fluid">
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
      <a class="navbar-brand" href="#"><img src="https://dbmenos.com/img/finamigo.png" class="img-fluid" alt="Finamigo Logo" width="150"></a>
      <form  align="left">
        <button style="width: 15rem; background-color: #d3157b"
                      class="btn btn-icon text-white nav-link-inner--text init">APERTURAR CUENTA</button>
      </form>
    </div>
  </div>
</nav>
!-->
    </section>
  </div>
</template>
<script>
import BaseNav from "@/components/BaseNav";
import BaseDropdown from "@/components/BaseDropdown";
import CloseButton from "@/components/CloseButton";
import moment from "moment";
import Vue from "vue";
import { V } from "../V";
export default {
  async created() {
    this.session = localStorage.getItem(V.STORE_KEYS.SESSION);
    this.user = JSON.parse(localStorage.getItem(V.STORE_KEYS.USER));
    this.$forceUpdate();
    //await this.checkSession()
    this.getAccountData();
  },
  computed: {
    //        user() { return localStorage.getItem(V.STORE_KEYS.USER) },
    //        session() { return localStorage.getItem(V.STORE_KEYS.SESSION) }
  },
  data() {
    return {
      session: null,
      account: {},
      user: {},
    };
  },
  mounted() {
    this.$root.$on("refreshHeader", (query) => {
      this.$forceUpdate();
      this.session = localStorage.getItem(V.STORE_KEYS.SESSION);
      this.user = JSON.parse(localStorage.getItem(V.STORE_KEYS.USER));
    });
  },
  methods: {
    async logout() {
      this.$store.commit("setUser", {});
      localStorage.clear();
      await this.checkSession();
      this.getAccountData();
      this.$root.$emit("refreshHeader");
      //console.log("Cerrar sesión")
      this.$router.push("/").catch((err) => {});
    },
    async checkSession() {
      let _session = localStorage.getItem(this.$v.STORE_KEYS.SESSION);
      if (_session) {
        this.session = _session;
        let currentDate = moment();
        let expiresDate = moment(this.session.expires, "YYYY-MM-DD");

        if (
          expiresDate.isSame(currentDate) ||
          expiresDate.isBefore(currentDate)
        ) {
          this.logout();
        } else {
          try {
            let validSession = await this.verifySession();
            if (!validSession) {
              this.logout();
            }
          } catch (error) {
            this.logout();
          }
        }
      } else {
        this.session = {
          session: "",
        };
      }
    },
    getAccountData() {
      let _account = this.$store.getters.getAccount;
      if (_account) {
        this.account = _account;
      } else {
        this.account = {};
      }
    },
    goProfile() {
      let url = V.SERVER + V.OPS.GET_SIGNATURE_INFO;
      this.$axios.get(url, this.session).then((response) => {
        let data = response.data;
        if (data[V.API.SUCCESS]) {
          this.$router.push("/profile").catch((err) => {});
        }
      });
    },
    goRegister() {
      let url = V.SERVER + V.OPS.GET_PROGRAM_STEP;
      this.$axios.get(url).then((response) => {
        let data = response.data;
        if (data[V.API.SUCCESS]) {
          if (
            !data.row.basic_information ||
            !data.row.basic_information2 ||
            !data.row.address_information ||
            !data.row.official_id
          ) {
            this.$router.push("/start");
          } else if (
            data.row.basic_information &&
            data.row.basic_information2 &&
            data.row.address_information &&
            data.row.official_id &&
            !data.row.credit_report_signature
          ) {
            this.$router.push({
              path: "/signatureReport",
            });
          } else if (
            data.row.basic_information &&
            data.row.basic_information2 &&
            data.row.address_information &&
            data.row.official_id &&
            data.row.credit_report_signature &&
            !data.row.credit_report
          ) {
            this.$router.push({
              path: "/calcular",
            });
          } else if (
            data.row.basic_information &&
            data.row.basic_information2 &&
            data.row.address_information &&
            data.row.official_id &&
            data.row.credit_report_signature &&
            data.row.credit_report &&
            data.row.program_calculation &&
            !data.row.contract &&
            !data.row.active
          ) {
            this.$router.push({
              path: "/start2",
            });
          } else if (
            (data.row.basic_information &&
              data.row.basic_information2 &&
              data.row.address_information &&
              data.row.official_id &&
              data.row.credit_report_signature &&
              data.row.credit_report &&
              data.row.program_calculation &&
              data.row.contract) ||
            data.row.active
          ) {
            this.$router.push({
              path: "/profile",
            });
          } else {
            let url = V.SERVER + V.OPS.GET_PROGRAM_CALCULATION;
            this.$axios.get(url).then((response) => {
              let data = response.data;
              if (data[V.API.SUCCESS]) {
                if (data.row.debts) {
                  this.$router.push("/start");
                } else {
                  this.$router.push("/calculator").catch((err) => {});
                }
              } else if (data.reason === 1) {
                this.$router.push("/calculator").catch((err) => {});
              }
            });
          }
        }
      });
    },
    async verifySession() {
      try {
        let res = await this.$axios.post(V.OPS.VALIDATE_SESSION);
        if (res.data.valid) {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        //                console.log(error)
        return false;
      }
    },
  },
  updated() {
    //this.$forceUpdate()
    //this.getAccountData()
  },
  watch: {
    $route(to, from) {
      if (
        (to.name === "profile" || to.name === "calculator") &&
        from.name === "login"
      ) {
        this.checkSession();
        this.getAccountData();
      }
    },
  },
  components: {
    BaseNav,
    CloseButton,
    BaseDropdown,
  },
};
</script>
<style>
@font-face {
  font-family: Roboto-Bold;
  src: url(/fonts/Roboto_font/Roboto-Bold.ttf);
}
@font-face {
  font-family: Roboto-Black;
  src: url(/fonts/Roboto_font/Roboto-Black.ttf);
}
.init {
  font-size: 1.1rem;
  font-family: Roboto-Black;
}
</style>
