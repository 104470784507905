<template>
  <div class="position-relative">
    <section class="section section-lg pt-lg-0">
      <div class="container mt--4" style="padding: 5rem">
        <div class="col-lg-12 text-center"></div>
        <div class="row">
          <div class="col-lg-12 center">
            <card style="background-color: #ffff; border: none">
              <h4 style="font-size:25px; margin:2rem;" class="display-4">Te pedimos llenes los siguientes datos faltantes para poder 
                continuar con tu apertura de cuenta.</h4>
              <template>
                <form @submit.prevent>
                  <div class="row" >
                    <div class="col" 
                        v-if="dataMg == 'name'">
                      <base-input
                        alternative
                        label="Nombre (Solo Nombre sin Apellidos)"
                        input-classes="form-control-alternative"
                        v-model="client.name"
                      />
                    </div>
                    <div class="col" 
                        v-if="dataMg == 'lastname_1'">
                      <base-input
                        alternative
                        label="Primer Apellido"
                        input-classes="form-control-alternative"
                        v-model="client.lastname_1"
                      />
                    </div>
                    <div class="col" 
                         v-if="dataMg == 'lastname_2'">
                      <base-input
                        alternative
                        label="Segundo Apellido"
                        input-classes="form-control-alternative"
                        v-model="client.lastname_2"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col" 
                         v-if="dataMg == 'birthday'">
                                  <label class="form-control-label"
                                    >Fecha de nacimiento</label
                                  >
                                  <flat-pickr
                                    v-model="client.birthday"
                                    class="
                                      form-control form-control-alternative
                                    "
                                    data-vv-as="Fecha"
                                    name="date"
                                    style="background-color: #ffffff"
                                  />
                                </div>
                    <div class="col" v-if="dataMg == 'email'">
                      <base-input
                        alternative
                        label="Correo Electronico"
                        input-classes="form-control-alternative"
                        v-model="client.email"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col" v-if="dataMg == 'id_number'">
                      <base-input
                        alternative
                        label="Número de Identificacion"
                        input-classes="form-control-alternative"
                        v-model="client.id_number"
                      />
                    </div>
                    <div class="col" v-if="dataMg == 'curp'">
                      <base-input
                        alternative
                        label="CURP"
                        input-classes="form-control-alternative"
                        v-model="client.curp"
                      />
                    </div>
                    <div class="col" v-if="dataMg == 'rfc'">
                      <base-input
                        alternative
                        label="RFC"
                        input-classes="form-control-alternative"
                        v-model="client.rfc"
                      />
                    </div>
                       <div class="co-12" v-if="dataMg == 'gender'">
                    <base-dropdown>
                        <base-button
                          slot="title" type="outline" class="dropdown-toggle" style="margin-top: 10px; white-space: normal; font-size:1.5rem;"
                          :class="gender === null ? 'text-danger' : ''"
                          >{{
                            gender ? "Género " + gender : "Selecciona género"
                          }}</base-button
                        >
                        <a
                          class="dropdown-item"
                          v-on:click="setGender('Masculino')"
                          >Masculino</a
                        >
                        <a
                          class="dropdown-item"
                          v-on:click="setGender('Femenino')"
                          >Femenino</a
                        >
                      </base-dropdown>
                  </div>
                    <div class="co-12" v-if="dataMg == 'state_of_birth'">
                    <base-dropdown>
                        <base-input
                          slot="title" type="outline" class="dropdown-toggle" style="margin-top: 10px; white-space: normal; font-size:1.5rem;"
                          :class="stateOfBirth === null ? 'text-danger' : ''"
                          >{{
                            stateOfBirth
                              ? "Nacido en " + stateOfBirth
                              : "Estado de nacimiento"
                          }}</base-input
                        >
                        <a
                          class="dropdown-item"
                          v-for="(state, index) in mxStates"
                          :key="index"
                          v-on:click="setMxState(state)"
                          >{{ state.state }}</a
                        >
                      </base-dropdown>
                  </div>
                  </div>
                  <div class="row" v-if="!addressClient">
                    <div class="col">
                      <base-input
                        alternative
                        label="Calle"
                        input-classes="form-control-alternative"
                        v-model="client.addr_street"
                      />
                    </div>
                    <div class="col">
                      <base-input
                        alternative
                        label="Número exterior"
                        input-classes="form-control-alternative"
                        v-model="client.addr_extnum"
                      />
                    </div>
                    <div class="col">
                      <base-input
                        alternative
                        label="Número interior"
                        input-classes="form-control-alternative"
                        v-model="client.addr_intnum"
                      />
                    </div>
                  </div>
                   <div class="row" v-if="!addressClient">
                    <div class="col">
                      <base-input
                        alternative
                        label="Código Postal"
                        input-classes="form-control-alternative"
                        v-model="client.addr_postal_code"
                        v-on:input="postalCodeUpdated"
                      />
                    </div>
                    <div class="col">
                      <base-input
                        alternative
                        label="Colonia"
                        input-classes="form-control-alternative"
                        v-model="client.addr_sublocality"
                      />
                    </div>
                    <div class="col-1">
                      <base-dropdown v-if="sublocalities.length > 0">
                        <a
                          class="dropdown-item"
                          v-for="(sublocality, index) in sublocalities"
                          :key="index"
                          v-on:click="sublocalitySelected(sublocality)"
                          >{{ sublocality }}</a
                        >
                      </base-dropdown>
                    </div>
                    <div class="col">
                      <base-input
                        alternative
                        label="Ciudad"
                        input-classes="form-control-alternative"
                        v-model="client.addr_locality"
                      />
                    </div>
                  </div>
                  <div class="row d-flex justify-content-end">
                    <div class="col-6 text-right my-2">
                      <base-button
                        slot="title"
                        type="primary"
                        class="btn"
                        style="
                          background-color: #2b8c9c;
                          border-color: #2b8c9c;
                          color: #ffff;
                          width: 60%;
                          border-radius: 10px;
                        "
                        size="xl"
                        v-on:click="updateClientProfile"
                        >{{dataMg == null ? 'Guardar' : 'Continuar'}}</base-button
                      >
                    </div>
                  </div>
                </form>
              </template>
            </card>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import BaseDropdown from "../components/BaseDropdown";
import UploadFilesForm from "@/views/components/UploadFilesForm.vue";
import Dropbox from "../components/Dropbox";
import DropboxResize from "../components/DropboxResize";
import { V } from "../V";

export default {
  data() {
    return {
      session: null,
      urlLocal: null,
      phone: null,
      a: false,
      url: null,
      token: null,
      rows: 100,
      options: [
        { text: "Casado", value: "M" },
        { text: "Soltero", value: "S" },
      ],
      client: {},
      pag: 1,
      gender: null,
      stateOfBirth: null,
      locality: "",
      sublocalities: [],
      beneficiary: {},
      genderBeneficiary: null,

      pointer: "pointer",
      pointer2: "default",
      regEmail:
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      regPhone: /[0-9]{10}?/,
      payday: 15,
      userFiles: [],
      filetype: null,
      archivo: null,
      files: {
        ADDRESS: null,
      },
      fileId: true,
      fileId_b: true,
      urlAsesor: "",
      phone: "",
      postalCodes: [],
      dataMg:"",
      addressClient:false,
    };
  },
  created() {
    this.phone = JSON.parse(localStorage.getItem("phone"));
    this.session = JSON.parse(localStorage.getItem("session"));
    this.getClientProfile();
    this.getDataMissing();
  },
  computed: {
    mxStates() { return this.$store.state.mxStates; },
    relationBeneficiary() {
      return this.$store.state.relationBeneficiary;
    },
    economicActivities() {
      let activities = this.$store.state.economicActivities;
      if (!activities) this.$store.dispatch("downloadEconomicActivities");
      return activities;
    },
  },
  methods: {
    sublocalitySelected(sublocality) {
      this.client.addr_sublocality = sublocality;
      this.$forceUpdate();
    },
    setMxState(state) {
      this.client.state_of_birth = state.code;
      this.stateOfBirth = state.state;
      this.$forceUpdate();
    },
    setGender(gender) {
      this.client.gender = gender.substring(0, 1).toUpperCase();
      this.gender = gender;
      this.$forceUpdate();
    },
    getRelationBeneficiary() {
      for (let i = 0; i < this.relationBeneficiary.length; i++)
        if (this.relationBeneficiary[i].id === this.beneficiary.relationship)
          return this.relationBeneficiary[i].value;
      return null;
    },
    beneficiaryRelationSelected(relation) {
      this.beneficiary.relationship = relation;
      this.getRelationBeneficiary();
      this.$forceUpdate();
    },
    updateClientProfile() {
      if(this.dataMg == null){
      if (
        this.dataMg == null &&
        this.client.addr_street &&
        this.client.addr_extnum &&
        this.client.addr_postal_code &&
        this.client.addr_sublocality &&
        this.client.email &&
        this.client.addr_locality &&
        this.client.name &&
        this.client.lastname_1 &&
        this.client.lastname_2 &&
        this.client.curp &&
        this.client.rfc &&
        this.client.state_of_birth &&
        this.client.gender &&
        this.client.id_number 
      ) {
      let url = this.$v.SERVER + this.$v.USER_OPS.SET_USER;
      this.$axios.post(url, this.client).then((response) => {
        let data = response.data;
        if (data[this.$v.API.SUCCESS]) {
          this.addressClient=true;
          this.getDataMissing();
        }
      });
      } else {
        this.$notify({
              text: "Faltan datos por llenar",
              type: "error",
            });
      }
      }else{
        let url = this.$v.SERVER + this.$v.USER_OPS.SET_USER;
      this.$axios.post(url, this.client).then((response) => {
        let data = response.data;
        if (data[this.$v.API.SUCCESS]) {
          this.addressClient=true;
          this.getDataMissing();
        }
      });
      }
    },
    async set_beneficiary() {
      let url = this.$v.SERVER + this.$v.USER_OPS.SET_BENEFICIARY;
      let response = await this.$axios.post(url, this.beneficiary);
      let data = response.data;
      if (data[this.$v.API.SUCCESS]) {
        console.log("Todo beneficiario");
      }
    },
    getClientProfile() {
      let url = this.$v.SERVER + this.$v.USER_OPS.GET_USER;
      this.$axios.post(url).then((response) => {
        let data = response.data;
        if (data[this.$v.API.SUCCESS]) {
          this.client = response.data[this.$v.API.DATA];
          if (data[this.$v.API.DATA].gender){ 
            if(response.data[this.$v.API.DATA].gender == 'F'){
            this.gender ='Femenino'
            }else{
              this.gender ='Masculino'
            }
            };
            if (this.client.state_of_birth) {
          for (let i = 0; i < this.mxStates.length; i++) {
            let st = this.mxStates[i];
            if (this.client.state_of_birth === st.code) {
              this.stateOfBirth = st.state;
              break;
            }
          }
        }
        }
      });
    },
    getDataMissing() {
      let url = this.$v.SERVER + this.$v.USER_OPS.GET_DATA_MISSING;
      this.$axios.post(url).then((response) => {
        let data = response.data;
        if (data[this.$v.API.SUCCESS]) {
         this.dataMg=response.data[this.$v.API.DATA].missingField;
         if(this.dataMg == null){
           this.$router.push("/register").catch(()=>{});
         }else{
           this.$router.push("/datamissing").catch(()=>{});
         }
        }
      });
    },
    postalCodeUpdated(pcode) {
      if (pcode.length > 3) {
        let body={
          pc:pcode
        }
        let url = this.$v.SERVER + this.$v.CATALOG_OPS.POSTAL_CODES;
        this.$axios.post(url, body).then((response) => {
          let data = response.data;
          if (data.success) {
            this.postalCodes = response.data[this.$v.API.DATA];
            if (this.postalCodes.length === 1){
              this.postalCodeSelected(this.postalCodes[0]);
            }
            this.$forceUpdate();
          }
        });
      } else {
        this.postalCodes = [];
        this.locality = "";
        this.sublocalities = [];
        this.client.addr_sublocality = "";
      }
    },
    postalCodeSelected(postalCode) {
            this.client.addr_postal_code = postalCode.postal_code;
            this.sublocalities = postalCode["sublocality"].split(";");
            if (this.sublocalities.length === 1)
                this.client.addr_sublocality = this.sublocalities[0];
            this.client.addr_locality = postalCode["locality"];
        },
    setBeneficiaryGender(gender) {
      this.beneficiary.gender = gender.substring(0, 1).toUpperCase();
      this.$forceUpdate();
    },
    beneficiaryCivilStatusSelected(civiStatus) {
      if (civiStatus == "Soltero") this.beneficiary.civil_status = "S";
      else if (civiStatus == "Casado") this.beneficiary.civil_status = "M";
      this.$forceUpdate();
    },
    getStatusBeneficiary(status) {
      if (status == "S" || status == "Soltero") {
        return "Soltero";
      } else if (status == "M" || status == "Casado") {
        return "Casado";
      }
      this.$forceUpdate();
    },
    setEconomicActivity(activity) {
      this.client.economic_activity = activity;
      this.$forceUpdate();
    },
    getEconomicActivity() {
      for (let i = 0; i < this.economicActivities.length; i++)
        if (this.economicActivities[i].id === this.client.economic_activity)
          return this.economicActivities[i].activity;
      return null;
    },
    clientCivilStatusSelected(civiStatus) {
      if (civiStatus == "Soltero") this.client.civil_status = "Soltero";
      else if (civiStatus == "Casado") this.client.civil_status = "Casado";
      this.$forceUpdate();
    },
    async uploadFileComprobante(obj) {
      let file = obj.file;
      let formData = new FormData();
      formData.append("file", file);
      this.$showLoader(true);
      let bodyURL = {
        filename: obj.file.name,
      };
      let body = {
        filename: obj.file.name,
        type: "ADDRESS",
      };
      await this.$axios
        .post(V.SERVER + V.USER_OPS.UPLOAD_URL, bodyURL)
        .then((response) => {
          let data = response.data;
          if (response.data.success) {
            let fileURL = data[V.API.DATA].url;
            this.$axios.put(fileURL, file).then((putObjectResponse) => {
              if (putObjectResponse.status === 200) {
                this.$notify({
                  text: "Comprobante Cargado Correctamente",
                  type: "success",
                });
                this.$axios
                  .post(V.SERVER + V.USER_OPS.SET_FILE, body)
                  .then(async (response) => {
                    this.$showLoader(false);
                    if (response.data.success) {
                      let user = response.data.row;
                      this.a = true;
                    } else {
                      this.$showLoader(false);
                      this.$notify({
                        text: "Error, intenta nuevamente",
                        type: "warn",
                      });
                    }
                  })
                  .catch((e) => {
                    console.log(e);
                    this.$showLoader(false);
                    //alert("No se logró subir, intente nuevamente");
                  });
              } else {
                this.$showLoader(false);
                this.$notify({
                  text: "No se logró subir el Comprobante",
                  type: "warn",
                });
              }
            });
          } else {
            this.$showLoader(false);
            this.$notify({
              text: "Error, intenta nuevamente",
              type: "error",
            });
          }
        })
        .catch((e) => {
          console.log(e);
          this.$showLoader(false);
          //alert("No se logró subir, intente nuevamente");
        });
    },
  },
  components: {
    flatPickr,
    BaseDropdown,
    UploadFilesForm,
    Dropbox,
    DropboxResize,
  },
};
</script>

<style>
#tit {
  font-weight: 300;
  font-size: large;
}
@media (min-width: 300px) {
  .calcular {
    margin: 5%;
    padding: 6%;
  }
  .calcularbtn {
    margin-left: 1.3rem;
  }
  .separar {
    margin-bottom: 1rem;
  }
  .sta {
    margin-top: -420px;
  }
}
@media (min-width: 370px) {
  .calcular {
    margin: 5%;
    padding: 6%;
  }
  .calcularbtn {
    margin-left: 2.8rem;
  }
  .separar {
    margin-bottom: 1rem;
  }
  .sta {
    margin-top: -420px;
  }
}
@media (min-width: 420px) {
  .calcular {
    margin: 5%;
    padding: 6%;
  }
  .calcularbtn {
    margin-left: 4.2rem;
  }
  .separar {
    margin-bottom: 1rem;
  }
  .sta {
    margin-top: -420px;
  }
}
@media (min-width: 768px) {
  .calcular {
    margin: 5%;
    padding: 3%;
    align-content: center;
  }
  .calcularbtn {
    margin-left: 13.5rem;
  }

  .sta {
    margin-top: -410px;
  }
}

@media (min-width: 992px) {
  .calcular {
    margin-left: 70%;
  }
  .calcularbtn {
    margin-left: 20.5rem;
  }
}

@media (min-width: 1200px) {
  .calcular {
    margin-left: 70%;
  }
  .calcularbtn {
    margin-left: 23rem;
  }
}
</style>
